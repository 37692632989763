import { observable, action, makeObservable } from 'mobx';
import { getItem, removeItem, setItem } from '@/utils/storage';
import logger from '@/utils/logger';

class AuthStoreClass {
  constructor() {
    makeObservable(this, {
      isLoggedIn: observable,
      token: observable,
      profile: observable,
      setToken: action,
      setProfile: action,
      resetState: action,
    });
  }

  token: string | null = getItem('TOKEN');
  isLoggedIn = !!getItem('TOKEN');
  profile: Profile | null = null;

  setToken = (token: string | null) => {
    logger('AUTH', `authorization: ${token}`);

    if (token) {
      setItem('TOKEN', token);
    } else {
      removeItem('TOKEN');
    }

    this.isLoggedIn = !!token;
    this.token = token;
  };

  setProfile = (profile: Profile | null) => {
    logger('AUTH', `Fetched profile: ${profile?.email}`);

    this.profile = profile;
  };

  resetState = () => {
    logger('AUTH', 'resetState');

    this.setToken(null);
    this.setProfile(null);
  };
}

const AuthStore = new AuthStoreClass();

export default AuthStore;
