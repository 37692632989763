import { useNavigate } from 'react-router-dom';
import { useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Badge, Flex, HStack, IconButton, Text } from '@chakra-ui/react';
import { FaEye, FaFileContract, FaTrash } from 'react-icons/fa';
import { IoAdd, IoReload } from 'react-icons/io5';

import DataTable from '@/components/Table';
import { getContracts, removeContract } from '@/api/contract';
import Pagination from '@/components/Pagination';
import usePageInfo from '@/hooks/usePageInfo';
import usePagination from '@/hooks/usePagination';
import useRequestState from '@/hooks/useRequestState';
import ActionButton from '@/components/ActionButton';
import AppStore from '@/stores/app';
import { formatDate } from '@/utils/date';
import ConfirmDialog from '@/components/Dialogs/ConfirmDialog';
import { ROUTES } from '@/constants/routes';
import { mapContractStatusToColor } from '@/utils/contracts';
import ContractForm from './components/form';
import ActionBar from '@/components/ActionBar';

const UnitsPage = () => {
  usePageInfo({ title: 'pages.contracts' });

  const { selectedProperty } = AppStore;

  const [toDelete, setTodelete] = useState<Contract | null>(null);
  const [selected, setSelected] = useState<Contract | null | undefined>(undefined);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleDialogToggle = useCallback((data?: Contract | null) => {
    setTodelete(data ?? null);
  }, []);

  const {
    page,
    limit,
    sortBy,
    order,
    search,
    setPage,
    toggleOrder,
    setLimit,
    setSearch,
  } = usePagination<Contract>('createdAt');

  const { data, trigger: dataTrigger } = useRequestState<PaginateResult<Contract>>(
    () => getContracts({ page, limit, sortBy, order, search }),
    [page, limit, sortBy, order, selectedProperty, search],
  );

  const onDeleteSuccess = useCallback(() => {
    dataTrigger();
    handleDialogToggle();
  }, [dataTrigger, handleDialogToggle]);

  const { trigger: deleteTrigger } = useRequestState<'ok'>(
    () => removeContract(toDelete?._id!),
    [toDelete],
    { condition: false, onSuccess: onDeleteSuccess },
  );

  return (
    <>
      <Flex direction="column" h="100%">
        <ActionBar
          setSearch={setSearch}
          stats={
            <ActionButton
              mini
              hoverable={false}
              name={t('contracts.total')}
              value={`${data?.totalDocs ?? '-'}`}
              icon={FaFileContract}
            />
          }
          actions={
            <>
              <ActionButton mini icon={IoAdd} onClick={() => setSelected(null)} />

              <ActionButton mini icon={IoReload} onClick={dataTrigger} />
            </>
          }
        />

        <DataTable<
          Contract,
          'client' | 'unit' | 'actions' | 'expirationDays' | 'monthlyPrice'
        >
          mt="20px"
          sortBy={sortBy}
          order={order}
          onSort={toggleOrder}
          data={data?.docs || []}
          columns={[
            {
              id: 'status',
              header: t('contracts.status'),
              accessor: 'status',
              isSortable: true,
              cell: (data: Contract) => (
                <Badge colorScheme={mapContractStatusToColor(data.status)}>
                  {t(`contracts.statuses.${data.status}`)}
                </Badge>
              ),
            },
            {
              id: 'client',
              header: t('contracts.client'),
              accessor: 'client',
              cell: (data: Contract) => (
                <Text>
                  {data.data.client.name} {data.data.client.lastName}
                </Text>
              ),
            },
            {
              id: 'unit',
              header: t('contracts.unit'),
              accessor: 'unit',
              cell: (data: Contract) => <Text>{data.data.unit.name}</Text>,
            },
            {
              id: 'monthlyPrice',
              header: t('contracts.monthly_price'),
              accessor: 'monthlyPrice',
              cell: (data: Contract) => <Text>{data.data.unit.price}</Text>,
            },
            {
              id: 'signedAt',
              header: t('contracts.signed_date'),
              accessor: 'signedAt',
              isSortable: true,
              cell: (data: Contract) => <Text>{formatDate(data.signedAt)}</Text>,
            },
            {
              id: 'startDate',
              header: t('contracts.start_date'),
              accessor: 'startDate',
              isSortable: true,
              cell: (data: Contract) => <Text>{formatDate(data.startDate)}</Text>,
            },
            {
              id: 'endDate',
              header: t('contracts.end_date'),
              accessor: 'endDate',
              isSortable: true,
              cell: (data: Contract) => <Text>{formatDate(data.endDate)}</Text>,
            },
            {
              id: 'expirationDays',
              header: t('contracts.expiration_days'),
              accessor: 'expirationDays',
              isSortable: true,
              center: true,
              cell: (data: Contract) => (
                <Text>{data.data.property.invoiceExpirationDays}</Text>
              ),
            },
            {
              id: 'actions',
              header: t('contracts.actions'),
              accessor: null,
              center: true,
              cell: (data: Contract) => (
                <HStack justify="center">
                  <IconButton
                    aria-label="View"
                    size="sm"
                    icon={<FaEye />}
                    onClick={() => navigate(`${ROUTES.CONTRACTS}/${data._id}`)}
                  />

                  <IconButton
                    aria-label="Delete"
                    size="sm"
                    icon={<FaTrash />}
                    disabled={data.status !== 'DRAFT'}
                    onClick={() => handleDialogToggle(data)}
                  />
                </HStack>
              ),
            },
          ]}
          pagination={
            <Pagination
              {...data}
              onPageSizeChange={setLimit}
              onPageChange={setPage}
              limit={limit}
            />
          }
        />
      </Flex>

      <ConfirmDialog
        isOpen={!!toDelete}
        title={t('contracts.delete_confirmation_title')}
        body={t('contracts.delete_confirmation_body', {
          client: `${toDelete?.data.client.name} ${toDelete?.data.client.lastName}`,
        })}
        onConfirm={deleteTrigger}
        onCancel={() => handleDialogToggle()}
      />

      <ContractForm data={selected} setSelected={setSelected} onSubmit={dataTrigger} />
    </>
  );
};

export default observer(UnitsPage);
