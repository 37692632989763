import { createIcon } from '@chakra-ui/react';

export const Logo = createIcon({
  displayName: 'Logo',
  viewBox: '-86.53846153846155 -133.74125874125875 3157.3426573426573 1450.945482422883',
  path: (
    <g transform="scale(7.8671328671328675) translate(10, 10)">
      <defs id="SvgjsDefs1110" />
      <g
        id="SvgjsG1111"
        transform="matrix(0.15514184397163122,0,0,0.15514184397163122,-30.237588652482273,-20.832003664463123)"
        fill="inherit"
      >
        <g xmlns="http://www.w3.org/2000/svg">
          <polygon points="565,922.1 563.3,922.2 562,922.1 562,922.2 124,936.8 124,975 562.3,975 565.2,975 876,975 876,936.8 565,922.3     " />
          <polygon points="565,795.8 124,845.2 124,883.7 565,849  " />
          <polygon points="565,666.2 124,751.2 124,789.7 565,719.4  " />
          <polygon points="565,539.9 124,659.6 124,698.1 565,593.1  " />
          <polygon points="565,406.9 124,563.1 124,601.7 565,460.1  " />
          <polygon points="565,280.6 124,471.6 124,510.1 565,333.8  " />
          <polygon points="565,151 124,377.5 124,416.1 565,204.2  " />
          <polygon points="565,27.1 565,24.7 563.3,25.7 562,24.7 562,26.4 124,286 124,324.5 563.5,78.8 876,324.5 876,286  " />
        </g>
      </g>
      <g
        id="SvgjsG1112"
        transform="matrix(4.959262873514646,0,0,4.959262873514646,132.04073712648534,14.162948505941415)"
        fill="inherit"
      >
        <path d="M1 20 l0 -16 l2 0 l0 14 l2.6 0 l0 2 l-4.6 0 z M9.05 20 l0 -16 l4.6 0 l0 1.6 l-2.6 0 l0 5.2 l2.2 0 l0 1.6 l-2.2 0 l0 5.6 l2.6 0 l0 2 l-4.6 0 z M17.3 20 l0 -16 l4.6 0 l0 1.6 l-2.6 0 l0 5.2 l2.2 0 l0 1.6 l-2.2 0 l0 5.6 l2.6 0 l0 2 l-4.6 0 z M24.75 20 l3.6 -14.4 l-3.2 0 l0 -1.6 l5.6 0 l-3.5 14 l3.1 0 l0 2 l-5.6 0 z M34.2 20 l0 -16 l2 0 l0 14 l2.6 0 l0 2 l-4.6 0 z M41.45 4 l2 0 l1.2 6.16 l0.2 0 l1.2 -6.16 l2 0 l-2.3 9.04 l0 6.96 l-2 0 l0 -6.96 z" />
      </g>
    </g>
  ),
});
