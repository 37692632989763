import { useEffect, useState } from 'react';
import {
  Box,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Spinner,
  Text,
  Badge,
  RadioGroup,
  Radio,
  Stack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import { IoGridOutline } from 'react-icons/io5';
import { observer } from 'mobx-react-lite';

import useRequestState from '@/hooks/useRequestState';
import NoData from '@/components/NoData';
import { getUnits } from '@/api/unit';
import AppStore from '@/stores/app';

const UnitStep = () => {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const { property } = AppStore;

  const requestCriteria = search.length >= 1;

  const {
    register,
    control,
    setValue,
    clearErrors,
    watch,
    formState: { errors },
  } = useFormContext();

  const value = watch('unit');

  const { data, loading } = useRequestState<PaginateResult<Unit>>(
    () =>
      getUnits({
        page: 1,
        limit: 5,
        sortBy: 'name',
        order: 'asc',
        search,
      }),
    [search],
    { condition: requestCriteria },
  );

  useEffect(() => {
    clearErrors('unit');
  }, [clearErrors, search]);

  useEffect(() => {
    if (!requestCriteria) {
      setValue('unit', '');
    }
  }, [requestCriteria, setValue]);

  const handleChange = (value: string) => {
    setValue('client', value);
  };

  return (
    <Box minH="300px">
      <InputGroup>
        <InputLeftElement pointerEvents="none">
          <IoGridOutline color="gray.300" />
        </InputLeftElement>

        <Input
          autoFocus
          placeholder={t('contract_form.unit_search')}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />

        <InputRightElement>
          <Spinner size="sm" visibility={loading ? 'visible' : 'hidden'} />
        </InputRightElement>
      </InputGroup>

      {errors.unit?.message && (
        <Text fontSize="sm" mt="10px" color="red.300">
          {errors?.unit?.message as string}
        </Text>
      )}

      <Controller
        name="unit"
        control={control}
        rules={{ required: t('form.required') }}
        defaultValue={null}
        render={() => (
          <RadioGroup onChange={handleChange} value={value} flexDirection="column">
            {data?.docs.length ? (
              <Stack my="20px" w="100%">
                {data.docs.map(({ _id, name, price, comments, deposit }) => (
                  <Radio
                    key={_id}
                    {...register('unit', { required: t('form.required') })}
                    value={_id}
                  >
                    <Badge colorScheme="green" fontSize="15px" ml="5px">
                      {name}
                    </Badge>
                    : {price} {property.currency} / {deposit} {property.currency} (
                    {comments && comments})
                  </Radio>
                ))}
              </Stack>
            ) : (
              <Box my="20px">
                <NoData />
              </Box>
            )}
          </RadioGroup>
        )}
      />
    </Box>
  );
};

export default observer(UnitStep);
