export const DEFAULT_FORM_DATA: UnitFormData = {
  _id: '',
  isActive: true,

  name: '',
  deposit: 0,
  price: 0,
  latePaymentPenaltyPercent: 0,
  comments: '',

  property: undefined,
  organization: undefined,

  createdAt: new Date(),
  updatedAt: new Date(),
};
