import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import LayoutStore from '@/stores/layout';

type Props = {
  title: string;
};

const usePageInfo = ({ title }: Props) => {
  const { t } = useTranslation();
  const { setPageInfo } = LayoutStore;

  useEffect(() => {
    setPageInfo({ title: t(title) });
  }, [setPageInfo, t, title]);
};

export default usePageInfo;
