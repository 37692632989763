import { Flex, Icon, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { TbDatabaseSearch } from 'react-icons/tb';

type Props = {
  message?: string;
};

const NoData = ({ message }: Props) => {
  const { t } = useTranslation();

  return (
    <Flex justify="center" align="center" direction="column" gap={2}>
      <Icon as={TbDatabaseSearch} color="gray.500" fontSize={35} />

      <Text fontSize="lg" color="gray.500">
        {message ?? t('common.no_data')}
      </Text>
    </Flex>
  );
};

export default NoData;
