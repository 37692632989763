import { useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import {
  Badge,
  Flex,
  HStack,
  IconButton,
  Text,
  Tooltip,
  useToast,
} from '@chakra-ui/react';
import { IoAdd, IoReload } from 'react-icons/io5';
import { TbReceipt2 } from 'react-icons/tb';
import { FaEnvelope, FaTrash } from 'react-icons/fa';
import { HiReceiptRefund } from 'react-icons/hi';
import { MdRefresh } from 'react-icons/md';

import { getInvoices, removeInvoice, sendInvoice, refundInvoice } from '@/api/invoice';
import { formatDate } from '@/utils/date';
import AppStore from '@/stores/app';
import DataTable from '@/components/Table';
import Pagination from '@/components/Pagination';
import usePagination from '@/hooks/usePagination';
import useRequestState from '@/hooks/useRequestState';
import ActionButton from '@/components/ActionButton';
import Search from '@/components/Search';
import ConfirmDialog from '@/components/Dialogs/ConfirmDialog';
import { mapInvoicesToColor, mapTypeToColor } from '@/utils/invoices';
import InvoiceForm from './form';
import ActionBar from '@/components/ActionBar';

type Props = {
  title?: string;
  contract?: Contract;
  byContract?: boolean;
  layout?: {
    search?: boolean;
    total?: boolean;
    add?: boolean;
    reload?: boolean;
  };
  miniActions?: boolean;
  extraActions?: {
    beforeAll?: React.ReactNode;
    before?: React.ReactNode;
    after?: React.ReactNode;
    afterAll?: React.ReactNode;
  };
};

const InvoicesTable = ({
  title,
  contract,
  byContract = false,
  miniActions = false,
  layout = {
    search: true,
    total: true,
    add: true,
    reload: true,
  },
}: Props) => {
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [actionType, setActionType] = useState<'delete' | 'send' | 'refund' | null>(null);
  const [actionData, setActionData] = useState<Invoice | null>(null);
  const [selected, setSelected] = useState<Invoice | null | undefined>(undefined);

  const handleConfirmDialog = useCallback(
    (type: 'delete' | 'send' | 'refund', data?: Invoice | null) => {
      setActionType(type);
      setActionData(data ?? null);
      setIsConfirmDialogOpen(!!data);
    },
    [],
  );

  const toast = useToast();
  const { t } = useTranslation();
  const { selectedProperty } = AppStore;
  const {
    page,
    limit,
    sortBy,
    order,
    search,
    setPage,
    toggleOrder,
    setLimit,
    setSearch,
  } = usePagination<Invoice>('createdAt');

  const conditionByContract = byContract ? !!contract : true;

  const { data, trigger: dataTrigger } = useRequestState<PaginateResult<Invoice>>(
    () => getInvoices({ page, limit, sortBy, order, search }, contract?._id),
    [page, limit, sortBy, order, selectedProperty, search, contract?._id],
    { condition: conditionByContract },
  );

  const onDeleteSuccess = useCallback(() => {
    dataTrigger();
    setIsConfirmDialogOpen(false);
  }, [dataTrigger]);

  const onSendSuccess = useCallback(() => {
    dataTrigger();
    setIsConfirmDialogOpen(false);
  }, [dataTrigger]);

  const onRefundSuccess = useCallback(
    (res: string) => {
      dataTrigger();
      setIsConfirmDialogOpen(false);

      toast({
        title: res,
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    },
    [dataTrigger, toast],
  );

  const { trigger: deleteTrigger, loading: deleteLoading } = useRequestState<'ok'>(
    () => removeInvoice(actionData?._id!),
    [actionData],
    { condition: false, onSuccess: onDeleteSuccess },
  );

  const { trigger: sendTrigger, loading: sendLoading } = useRequestState<'ok'>(
    () => sendInvoice(actionData?._id!),
    [actionData],
    { condition: false, onSuccess: onSendSuccess },
  );

  const { trigger: refundTrigger, loading: refundLoading } = useRequestState<'ok'>(
    () => refundInvoice(actionData?._id!),
    [actionData],
    { condition: false, onSuccess: onRefundSuccess },
  );

  const handleConfirm = useCallback(async () => {
    if (actionType === 'delete') {
      deleteTrigger();
    } else if (actionType === 'send') {
      sendTrigger();
    } else if (actionType === 'refund') {
      refundTrigger();
    }
  }, [actionType, deleteTrigger, sendTrigger, refundTrigger]);

  const getMiniActions = () => {
    if (!miniActions) return undefined;

    const result = [];

    if (layout.add) {
      result.push({ icon: IoAdd, onClick: () => setSelected(null) });
    }

    if (layout.reload) {
      result.push({ icon: MdRefresh, onClick: dataTrigger });
    }

    return result;
  };

  const loading = deleteLoading || sendLoading || refundLoading;

  return (
    <>
      <Flex direction="column" h="100%">
        {!miniActions && (
          <ActionBar
            setSearch={setSearch}
            stats={
              <ActionButton
                mini
                hoverable={false}
                name={t('invoices.total')}
                value={`${data?.totalDocs ?? '-'}`}
                icon={TbReceipt2}
              />
            }
            actions={
              <>
                <ActionButton mini icon={IoAdd} onClick={() => setSelected(null)} />
                <ActionButton mini icon={IoReload} onClick={dataTrigger} />
              </>
            }
          />
        )}

        <DataTable<
          Invoice,
          'client' | 'unit' | 'actions' | 'items.quantity' | 'items.price'
        >
          mt="20px"
          title={title}
          sortBy={sortBy}
          order={order}
          onSort={toggleOrder}
          data={data?.docs || []}
          search={
            miniActions && layout.search ? (
              <Search
                mini
                reverseColor
                onChange={setSearch}
                placeholder="invoices.search_by_client"
              />
            ) : undefined
          }
          actions={getMiniActions()}
          columns={[
            {
              id: 'status',
              header: t('invoices.status'),
              accessor: 'status',
              isSortable: true,
              cell: (row) => (
                <Badge colorScheme={mapInvoicesToColor(row.status)}>
                  {t(`invoices.statuses.${row.status}`)}
                </Badge>
              ),
            },
            {
              id: 'unit',
              header: t('invoices.unit'),
              accessor: 'unit',
              cell: ({ contract }) => <Text>{`${contract?.data.unit.name}`}</Text>,
            },
            {
              id: 'amount',
              header: t('invoices.amount'),
              accessor: 'amount',
              isSortable: true,
            },
            {
              id: 'items.price',
              header: t('invoices.penalties'),
              accessor: 'items.price',
              cell: (row) => (
                <Text>
                  {row.items?.find((el) => el.type === 'OVERDUE_PENALTY')?.price ?? '-'}
                </Text>
              ),
            },
            {
              id: 'items.quantity',
              header: t('invoices.overdue_days'),
              accessor: 'items.quantity',
              cell: (row) => (
                <Text>
                  {row.items?.find((el) => el.type === 'OVERDUE_PENALTY')?.quantity ??
                    '-'}
                </Text>
              ),
            },
            {
              id: 'currency',
              header: t('invoices.currency'),
              accessor: 'currency',
              isSortable: true,
            },
            {
              id: 'client',
              header: t('invoices.client'),
              accessor: 'client',
              cell: ({ contract }) => (
                <Text>
                  {`${contract?.data.client.name} ${contract?.data.client.lastName}`}
                </Text>
              ),
            },
            {
              id: 'dueDate',
              header: t('invoices.due_date'),
              accessor: 'dueDate',
              isSortable: true,
              cell: (row) => <Text whiteSpace="nowrap">{formatDate(row.dueDate)}</Text>,
            },
            {
              id: 'paidAt',
              header: t('invoices.paid_at'),
              accessor: 'paidAt',
              isSortable: true,
              cell: (row) => <Text whiteSpace="nowrap">{formatDate(row.paidAt)}</Text>,
            },
            {
              id: 'type',
              header: t('invoices.type'),
              accessor: 'type',
              isSortable: true,
              cell: (row) => (
                <Badge colorScheme={mapTypeToColor(row.type)}>
                  {t(`invoices.types.${row.type}`)}
                </Badge>
              ),
            },
            {
              id: 'actions',
              header: t('invoices.actions'),
              accessor: null,
              center: true,
              cell: (data: Invoice) => (
                <HStack justify="center">
                  <Tooltip label={t('invoices.send_invoice')} aria-label="Send Invoice">
                    <IconButton
                      aria-label="Send Email"
                      size="sm"
                      icon={<FaEnvelope />}
                      onClick={() => {
                        handleConfirmDialog('send', data);
                      }}
                    />
                  </Tooltip>

                  <Tooltip label={t('invoices.make_refund')} aria-label="Make Refund">
                    <IconButton
                      aria-label="Make Refund"
                      size="sm"
                      disabled={data.status !== 'PAID'}
                      icon={<HiReceiptRefund />}
                      onClick={() => {
                        handleConfirmDialog('refund', data);
                      }}
                    />
                  </Tooltip>

                  <Tooltip
                    label={data.status !== 'DRAFT' && t('invoices.delete_invoice_info')}
                    aria-label="Send Invoice"
                  >
                    <IconButton
                      aria-label="Delete"
                      size="sm"
                      icon={<FaTrash />}
                      disabled={data.status !== 'DRAFT'}
                      onClick={() => handleConfirmDialog('delete', data)}
                    />
                  </Tooltip>
                </HStack>
              ),
            },
          ]}
          pagination={
            <Pagination
              {...data}
              onPageSizeChange={setLimit}
              onPageChange={setPage}
              limit={limit}
            />
          }
        />
      </Flex>

      {conditionByContract && (
        <InvoiceForm
          contract={contract}
          data={selected}
          setSelected={setSelected}
          onSubmit={dataTrigger}
        />
      )}

      <ConfirmDialog
        loading={loading}
        isOpen={isConfirmDialogOpen}
        title={
          actionType === 'delete'
            ? t('invoices.delete_confirmation_title')
            : t('invoices.send_confirmation_title')
        }
        body={
          actionType === 'delete'
            ? t('invoices.delete_confirmation_body', {
                client: `${actionData?.contract.data.client.name} ${actionData?.contract.data.client.lastName}`,
                unit: actionData?.contract.data.unit.name,
              })
            : t('invoices.send_confirmation_body', {
                client: `${actionData?.contract.data.client.name} ${actionData?.contract.data.client.lastName}`,
                unit: `${actionData?.contract.data.unit.name}`,
              })
        }
        onConfirm={handleConfirm}
        onCancel={() => setIsConfirmDialogOpen(false)}
      />
    </>
  );
};

export default observer(InvoicesTable);
