import { useMemo, useState } from 'react';
import {
  Box,
  Card,
  Flex,
  Icon,
  SimpleGrid,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react';
import { FaDatabase, FaDollarSign, FaFileContract, FaPeopleCarry } from 'react-icons/fa';
import { GiPayMoney } from 'react-icons/gi';
import { useTranslation } from 'react-i18next';
import { TbClockExclamation } from 'react-icons/tb';
import { observer } from 'mobx-react-lite';

import PieCard from '@/components/PieCard';
import usePageInfo from '@/hooks/usePageInfo';
import useRequestState from '@/hooks/useRequestState';
import { propertyStats } from '@/api/stats';
import ActionButton from '@/components/ActionButton';
import AppStore from '@/stores/app';
import { getInvoiceChartData, getInvoicesLegendChart } from '@/utils/invoices';
import { getContractChartData, getContractLegendChart } from '@/utils/contracts';
import { formatDate, getDatesByFilter } from '@/utils/date';
import TotalSpent from '@/components/TotalSpent';
import Dropdown from '@/components/DropDown';
import { DATE_FILTERS } from '@/constants/app';

const dateFilters = {
  [DATE_FILTERS.THIS_MONTH]: {
    key: 'date_filters.this_month',
    value: DATE_FILTERS.THIS_MONTH,
  },
  [DATE_FILTERS.LAST_MONTH]: {
    key: 'date_filters.last_month',
    value: DATE_FILTERS.LAST_MONTH,
  },
  [DATE_FILTERS.THIS_YEAR]: {
    key: 'date_filters.this_year',
    value: DATE_FILTERS.THIS_YEAR,
  },
  [DATE_FILTERS.LAST_YEAR]: {
    key: 'date_filters.last_year',
    value: DATE_FILTERS.LAST_YEAR,
  },
};

const HomePage = () => {
  usePageInfo({ title: 'pages.home' });

  const { t } = useTranslation();
  const dropdownbg = useColorModeValue('white', 'whiteAlpha.100');

  const [selectedDateFilter, setSelectedDateFilter] = useState<
    valueof<typeof DATE_FILTERS>
  >(dateFilters[DATE_FILTERS.THIS_MONTH].value);

  const { property } = AppStore;

  const { data } = useRequestState<PropertyStats>(
    () => {
      const filter = getDatesByFilter(selectedDateFilter);

      return propertyStats({
        startDate: filter.startDate,
        endDate: filter.endDate,
      });
    },
    [selectedDateFilter, property?._id],
    { condition: !!property?._id },
  );

  const invoiceData = useMemo(
    () => ({
      data: getInvoiceChartData(data?.invoices, t),
      legends: getInvoicesLegendChart(data?.invoices, t),
    }),
    [data?.invoices, t],
  );

  const contractData = useMemo(
    () => ({
      data: getContractChartData(data?.contracts, t),
      legends: getContractLegendChart(data?.contracts, t),
    }),
    [data?.contracts, t],
  );

  return (
    <Box mb="20px">
      <Box mb="20px">
        <Dropdown
          translate
          bg={dropdownbg}
          width={200}
          bgActive="brand.500"
          menuVariant="solid"
          items={Object.values(dateFilters)}
          selectedItem={dateFilters[selectedDateFilter]}
          placeholder=""
          labelKey="key"
          valueKey="value"
          onSelect={(item) => setSelectedDateFilter(item.value)}
        />
      </Box>

      <SimpleGrid columns={{ base: 2, md: 3, lg: 5, '2xl': 6 }} gap="20px" mb="20px">
        <ActionButton
          mini
          hoverable={false}
          name={t('home.clients')}
          value={`${data?.clientsCount ?? '-'}`}
          icon={FaPeopleCarry}
        />

        <ActionButton
          mini
          hoverable={false}
          name={t('home.new_contracts')}
          value={`${data?.activeContractCount ?? '-'}`}
          icon={FaFileContract}
        />

        <ActionButton
          mini
          hoverable={false}
          name={t('home.revenue')}
          value={`${data?.revenue.toFixed(0) ?? '-'} ${property?.currency}`}
          icon={FaDollarSign}
        />

        <ActionButton
          mini
          hoverable={false}
          name={t('home.unpaid_revenue')}
          value={`${data?.unpaidRevenue.toFixed(0) ?? '-'} ${property?.currency}`}
          icon={GiPayMoney}
        />

        <ActionButton
          mini
          hoverable={false}
          name={t('home.late_payment_penalties')}
          value={`${data?.unpaidPenalties.toFixed(0) ?? '-'} ${property?.currency}`}
          icon={TbClockExclamation}
        />
      </SimpleGrid>

      <SimpleGrid columns={{ base: 1, md: 1, xl: 2, '2xl': 2 }} gap="20px" mb="20px">
        <Card>
          <TotalSpent
            title={t('home.revenue')}
            data={[
              { name: t('home.revenue'), data: data?.paidVsUnpaidMonthly?.paid ?? [] },
              {
                name: t('home.unpaid_revenue'),
                data: data?.paidVsUnpaidMonthly?.unpaid ?? [],
              },
            ]}
          />
        </Card>

        <Flex direction={{ base: 'column', md: 'row' }} gap="20px">
          <Card>
            <PieCard
              title={t('home.invoices')}
              data={invoiceData.data}
              legend={invoiceData.legends}
            />
          </Card>

          <Card>
            <PieCard
              title={t('home.contracts')}
              data={contractData.data}
              legend={contractData.legends}
            />
          </Card>
        </Flex>
      </SimpleGrid>

      <Box>
        <Text fontSize="xl" fontWeight="bold" mb="10px">
          {t('home.expiring_contracts')}
        </Text>

        <Card overflowX={{ sm: 'scroll', lg: 'hidden' }}>
          <Table variant="simple" flex={1}>
            <Thead zIndex="1">
              <Tr>
                <Th>#</Th>
                <Th>{t('contracts.unit')}</Th>
                <Th>{t('contracts.price')}</Th>
                <Th>{t('contracts.client')}</Th>
                <Th>{t('contracts.start_date')}</Th>
                <Th>{t('contracts.end_date')}</Th>
              </Tr>
            </Thead>

            <Tbody>
              {!data || data.expiringContracts.length === 0 ? (
                <Tr>
                  <Td textAlign="center" colSpan={6}>
                    <Flex justify="center" align="center" my="10px" gap="10px">
                      <Icon as={FaDatabase} fontSize={24} color="secondaryGray.600" />

                      {t('common.no_data')}
                    </Flex>
                  </Td>
                </Tr>
              ) : null}

              {data?.expiringContracts.map((contract, index) => (
                <Tr key={contract._id}>
                  <Td>{index + 1}</Td>
                  <Td>{contract.data.unit.name}</Td>
                  <Td>{`${contract.data.unit.price} ${contract.data.property?.currency}`}</Td>
                  <Td>
                    {contract.data.client.name} {contract.data.client.lastName}
                  </Td>
                  <Td>{formatDate(contract.startDate)}</Td>
                  <Td>{formatDate(contract.endDate)}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Card>
      </Box>
    </Box>
  );
};

export default observer(HomePage);
