export enum ROUTES {
  // AUTH
  AUTH = '/auth',

  // ROOT
  ROOT = '/admin',

  // MANAGER DASHBOARD
  DASHBOARD = '/admin/dashboard',
  CONTRACTS = '/admin/contracts',
  UNITS = '/admin/units',
  INVOICES = '/admin/invoices',
  CLIENTS = '/admin/clients',
  PAYMENTS = '/admin/invoices',
  ANNOUNCEMENTS = '/admin/announcements',
  TASKS = '/admin/tasks',

  // ADMIN DASHBOARD
  USERS = '/admin/users',
  OBJECTS = '/admin/properties',
  COMPLAINS = '/admin/complains',
  SETTINGS = '/admin/settings',
  IMPORT_EXPORT = '/admin/import-export',

  // SUPER ADMIN DASHBOARD
  ORGANIZATIONS = '/admin/organizations',
}
