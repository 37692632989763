import React from 'react';
import {
  Box,
  Flex,
  Drawer,
  DrawerBody,
  useColorModeValue,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import Content from './components/Content';
import LayoutStore from '@/stores/layout';

function Sidebar() {
  const variantChange = '0.2s linear';
  const sidebarBg = useColorModeValue('white', 'navy.800');
  const sidebarMargins = '0px';

  return (
    <Box display={{ sm: 'none', xl: 'block' }} position="fixed" minH="100%">
      <Box
        bg={sidebarBg}
        transition={variantChange}
        w="300px"
        h="100vh"
        m={sidebarMargins}
        minH="100%"
        overflowX="hidden"
        boxShadow="md"
      >
        <Content />
      </Box>
    </Box>
  );
}

export const SidebarResponsive = observer(() => {
  let sidebarBackgroundColor = useColorModeValue('white', 'navy.800');

  const { menuOpen, setMenuOpen } = LayoutStore;

  const btnRef = React.useRef(null);

  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  return (
    <Flex display={{ sm: 'flex', xl: 'none' }} alignItems="center">
      <Drawer
        isOpen={menuOpen}
        onClose={handleMenuClose}
        placement={'left'}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />

        <DrawerContent w="300px" maxW="300px" bg={sidebarBackgroundColor}>
          <DrawerBody maxW="300px" p="0">
            <Content
              closeBtn={<DrawerCloseButton zIndex="3" onClick={handleMenuClose} />}
            />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
});

export default Sidebar;
