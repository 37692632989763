import { CURRENCY, PAYMENT_SHIFT_DAY } from '@/constants/app';
import useRequestState from '@/hooks/useRequestState';
import { calculateShiftDate, formatDate } from '@/utils/date';
import {
  Stack,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Select,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
  Tooltip,
  Text,
  Input,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { getStripeList } from '@/api/stripe';

const PropertyPayments = () => {
  const { t } = useTranslation();

  const { data: stripeData, loading: stripeLoading } = useRequestState<StripeAccountList>(
    getStripeList,
    [],
    {
      onSuccess: (data) => {
        if (!data.length) {
          setValue('stripe', '');
        } else {
          setValue('stripe', data[0]._id);
        }
      },
    },
  );

  const {
    watch,
    formState: { errors },
    register,
    setValue,
  } = useFormContext<PropertyFormData>();

  const paymentShiftValue = watch('paymentShiftDay');

  return (
    <Stack spacing={4}>
      <Flex gap={{ base: 4, md: 2 }} direction="row">
        {/* Currency */}
        <FormControl isRequired isInvalid={!!errors.currency}>
          <FormLabel>{t('property_form.currency')}</FormLabel>

          <Select
            placeholder={t('property_form.select_currency')}
            {...register('currency', { required: t('form.required') })}
          >
            {Object.values(CURRENCY).map((currency) => (
              <option key={currency} value={currency}>
                {currency}
              </option>
            ))}
          </Select>

          <FormErrorMessage>
            <>{errors.currency?.message}</>
          </FormErrorMessage>
        </FormControl>

        {/* Stripe */}
        <FormControl isRequired isInvalid={!!errors.stripe}>
          <FormLabel>{t('property_form.stripe_accounts')}</FormLabel>

          <Select
            {...register('stripe', { required: t('form.required') })}
            disabled={stripeLoading}
            onChange={(e) => {
              setValue('stripe', e.target.value);
            }}
          >
            {stripeData?.length ? (
              stripeData?.map((stripe) => (
                <option key={stripe._id} value={stripe._id}>
                  {stripe.name}
                </option>
              ))
            ) : (
              <option value="">{t('property_form.no_stripe_accounts')}</option>
            )}
          </Select>

          <FormErrorMessage>
            <>{errors.stripe?.message}</>
          </FormErrorMessage>
        </FormControl>
      </Flex>

      <FormControl isRequired isInvalid={!!errors.invoiceExpirationDays}>
        <FormLabel>{t('property_form.invoice_expiration_days')}</FormLabel>

        <Input
          type="number"
          {...register('invoiceExpirationDays', { required: t('form.required') })}
        />

        <FormErrorMessage>{errors.invoiceExpirationDays?.message}</FormErrorMessage>
      </FormControl>

      {/* Payment Term */}
      <FormControl isRequired isInvalid={!!errors.paymentShiftDay} my={3}>
        <FormLabel>{t('property_form.payment_term')}</FormLabel>

        <Slider
          min={PAYMENT_SHIFT_DAY.MIN}
          max={PAYMENT_SHIFT_DAY.MAX}
          value={paymentShiftValue}
          onChange={(val) => setValue('paymentShiftDay', val)}
        >
          <Flex position="relative" w="100%" mt={5}>
            <SliderMark value={PAYMENT_SHIFT_DAY.MIN}>{PAYMENT_SHIFT_DAY.MIN}</SliderMark>

            <SliderMark value={PAYMENT_SHIFT_DAY.DEFAULT} transform="translateX(-4px)">
              {PAYMENT_SHIFT_DAY.DEFAULT}
            </SliderMark>

            <SliderMark value={PAYMENT_SHIFT_DAY.MAX} transform="translateX(-100%)">
              {PAYMENT_SHIFT_DAY.MAX}
            </SliderMark>
          </Flex>

          <SliderTrack>
            <SliderFilledTrack />
          </SliderTrack>

          <Tooltip
            hasArrow
            isOpen
            bg="brand.500"
            color="white"
            placement="top"
            label={`${paymentShiftValue}`}
          >
            <SliderThumb />
          </Tooltip>
        </Slider>

        <Flex direction="column" mt={5}>
          <Text fontWeight="bold">{t('property_form.payment_shift_example')}:</Text>

          <Flex direction="row" gap={5}>
            <Flex>{shiftedDifferentMonths(paymentShiftValue).january}</Flex>
            <Flex>{shiftedDifferentMonths(paymentShiftValue).february}</Flex>
            <Flex>{shiftedDifferentMonths(paymentShiftValue).april}</Flex>
          </Flex>
        </Flex>

        <FormErrorMessage>{errors.paymentShiftDay?.message}</FormErrorMessage>
      </FormControl>
    </Stack>
  );
};

const shiftedDifferentMonths = (shiftDay: number) => {
  const february = calculateShiftDate(new Date('02-01-2023'), shiftDay);
  const january = calculateShiftDate(new Date('01-01-2023'), shiftDay);
  const april = calculateShiftDate(new Date('04-01-2023'), shiftDay);

  return {
    february: formatDate(february, 'DD MMMM'),
    january: formatDate(january, 'DD MMMM'),
    april: formatDate(april, 'DD MMMM'),
  };
};

export default PropertyPayments;
