import { Box, Textarea } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useFormContext, Controller } from 'react-hook-form';

const CommentsStep = () => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  return (
    <Box>
      <Controller
        name="comments"
        control={control}
        render={({ field }) => (
          <Textarea
            placeholder={t('contract_form.comments')}
            rows={6}
            resize="none"
            maxLength={200}
            {...field}
          />
        )}
      />
    </Box>
  );
};

export default CommentsStep;
