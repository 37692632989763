import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Stack,
  Textarea,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

const CommentsStep = () => {
  const { t } = useTranslation();
  const {
    formState: { errors },
    register,
  } = useFormContext<InvoiceFormData>();

  return (
    <Stack spacing={4}>
      <Flex gap={{ base: 4, md: 2 }} direction={{ base: 'column', md: 'row' }}>
        <FormControl isRequired isInvalid={!!errors.amount}>
          <FormLabel>{t('invoice_form.payment_amount')}</FormLabel>

          <Input
            type="number"
            {...register('amount', { required: t('form.required') })}
          />

          <FormErrorMessage>{errors.amount?.message}</FormErrorMessage>
        </FormControl>
      </Flex>

      <Flex gap={{ base: 4, md: 2 }} direction={{ base: 'column', md: 'row' }}>
        <FormControl isRequired isInvalid={!!errors.description}>
          <FormLabel>{t('invoice_form.payment_product_description')}</FormLabel>

          <Textarea {...register('description', { required: t('form.required') })} />

          <FormErrorMessage>{errors.description?.message}</FormErrorMessage>
        </FormControl>
      </Flex>
    </Stack>
  );
};

export default CommentsStep;
