import { request } from '@/utils/request';

export const getInvoices = async (
  query: PaginationRequestType,
  contract?: Contract['_id'],
) =>
  request<PaginateResult<Invoice>>({
    url: '/invoice',
    query: { ...query, contract },
  });

export const createInvoice = async (body: InvoiceFormData) =>
  request<Invoice>({
    url: '/invoice',
    method: 'POST',
    body,
  });

export const removeInvoice = async (id: string) =>
  request<'ok'>({
    url: '/invoice',
    method: 'DELETE',
    param: id,
  });

export const sendInvoice = async (id: string) =>
  request<'ok'>({
    url: '/invoice/send',
    param: id,
  });

export const refundInvoice = async (id: string) =>
  request<'ok'>({
    url: '/invoice/refund',
    method: 'POST',
    body: { id },
  });
