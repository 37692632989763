import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import {
  Stack,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Button,
  HStack,
  Textarea,
} from '@chakra-ui/react';

import Popup from '@/components/Popup';
import { replyComplain } from '@/api/complains';
import useRequestState from '@/hooks/useRequestState';
import { DEFAULT_FORM_DATA } from './config';

type Props = {
  data: any;
  handleOnClose: () => void;
};

const ReplyModal = ({ data, handleOnClose }: Props) => {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    getValues,
    reset,
    formState: { errors },
  } = useForm<ComplainFormData>({
    defaultValues: DEFAULT_FORM_DATA,
  });

  const handleSuccess = () => {
    reset();
    handleOnClose();
  };

  const { loading, trigger: submitTrigger } = useRequestState<'ok'>(
    () => replyComplain(data?._id, { message: getValues('message') }),
    [],
    { condition: false, onSuccess: handleSuccess },
  );

  return (
    <Popup
      size="xl"
      isOpen={!!data}
      onClose={handleSuccess}
      closeOnOverlayClick={false}
      title={t('complain_form.reply_title', {
        client: `${data?.client.name} ${data?.client.lastName}`,
      })}
      content={
        <form>
          <Stack spacing={4}>
            <FormControl isRequired isInvalid={!!errors.message}>
              <FormLabel>{t('complain_form.message')}</FormLabel>

              <Textarea {...register('message', { required: t('form.required') })} />

              <FormErrorMessage>{errors.message?.message}</FormErrorMessage>
            </FormControl>
          </Stack>
        </form>
      }
      footer={
        <HStack>
          <Button
            disabled={loading}
            type="submit"
            variant="brand"
            onClick={handleSubmit(submitTrigger)}
          >
            {t('form.save')}
          </Button>

          <Button disabled={loading} variant="outline" onClick={() => handleOnClose()}>
            {t('form.cancel')}
          </Button>
        </HStack>
      }
    />
  );
};

export default ReplyModal;
