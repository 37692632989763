import { useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Badge, Flex, HStack, IconButton, Text, Tooltip } from '@chakra-ui/react';
import { FaHospitalUser, FaPencilAlt } from 'react-icons/fa';
import { IoAdd, IoReload } from 'react-icons/io5';

import DataTable from '@/components/Table';
import { getOrganizations } from '@/api/organization';
import Pagination from '@/components/Pagination';
import usePageInfo from '@/hooks/usePageInfo';
import usePagination from '@/hooks/usePagination';
import useRequestState from '@/hooks/useRequestState';
import ActionButton from '@/components/ActionButton';
import OrganizationForm from './components/form';
import ActionBar from '@/components/ActionBar';
import { HiSwitchHorizontal } from 'react-icons/hi';
import { switchOrganization } from '@/api/admin';
import AuthStore from '@/stores/auth';

const OrganizationPage = () => {
  usePageInfo({ title: 'pages.organizations' });

  const { t } = useTranslation();

  const { setToken } = AuthStore;

  const [selectedOrganization, setSelectedOrganization] = useState<
    Organization | null | undefined
  >(undefined);

  const { page, limit, sortBy, order, setPage, toggleOrder, setLimit } =
    usePagination<Organization>('name');

  const { data, trigger: dataTrigger } = useRequestState<PaginateResult<Organization>>(
    () => getOrganizations({ page, limit, sortBy, order }),
    [page, limit, sortBy, order],
  );

  const handleSwitch = ({ token }: { token: string }) => {
    setToken(token);
    window.location.reload();
  };

  const { trigger: switchTrigger } = useRequestState<{
    token: string;
  }>((id) => switchOrganization(id as Organization['_id']), [], {
    condition: false,
    onSuccess: handleSwitch,
  });

  const handleSubmit = useCallback(() => {
    dataTrigger();
  }, [dataTrigger]);

  return (
    <>
      <Flex direction="column" h="100%">
        <ActionBar
          stats={
            <ActionButton
              mini
              hoverable={false}
              name={t('organizations.total')}
              value={`${data?.totalDocs ?? '-'}`}
              icon={FaHospitalUser}
            />
          }
          actions={
            <>
              <ActionButton
                mini
                icon={IoAdd}
                onClick={() => setSelectedOrganization(null)}
              />

              <ActionButton mini icon={IoReload} onClick={dataTrigger} />
            </>
          }
        />

        <DataTable<Organization, 'actions'>
          mt="20px"
          sortBy={sortBy}
          order={order}
          onSort={toggleOrder}
          data={data?.docs || []}
          columns={[
            {
              id: 'name',
              header: t('organizations.name'),
              accessor: 'name',
              isSortable: true,
            },
            {
              id: 'isActive',
              header: t('organizations.status'),
              accessor: 'isActive',
              isSortable: true,
              cell: (organization: Organization) => (
                <Badge colorScheme={organization.isActive ? 'green' : 'red'}>
                  {organization.isActive ? 'Active' : 'Inactive'}
                </Badge>
              ),
            },
            {
              id: 'address',
              header: t('organizations.address'),
              accessor: 'address',
              cell: (organization: Organization) => <Text>{organization.address}</Text>,
            },
            {
              id: 'city',
              header: t('organizations.city'),
              accessor: 'city',
              isSortable: true,
            },
            {
              id: 'country',
              header: t('organizations.country'),
              accessor: 'country',
              isSortable: true,
            },
            {
              id: 'phone',
              header: t('organizations.phone'),
              accessor: 'phone',
              isSortable: true,
            },
            {
              id: 'email',
              header: t('organizations.email'),
              accessor: 'email',
              isSortable: true,
            },
            {
              id: 'actions',
              header: t('organizations.actions'),
              accessor: null,
              center: true,
              cell: (data: Organization) => (
                <HStack justify="center">
                  <Tooltip
                    label={t('organizations.switch_to') + ` ${data.name}`}
                    hasArrow
                  >
                    <IconButton
                      aria-label="Switch"
                      size="sm"
                      icon={<HiSwitchHorizontal />}
                      onClick={() => switchTrigger(data._id)}
                    />
                  </Tooltip>

                  <IconButton
                    aria-label="Update"
                    size="sm"
                    icon={<FaPencilAlt />}
                    onClick={() => setSelectedOrganization(data)}
                  />
                </HStack>
              ),
            },
          ]}
          pagination={
            <Pagination
              {...data}
              onPageSizeChange={setLimit}
              onPageChange={setPage}
              limit={limit}
            />
          }
        />
      </Flex>

      <OrganizationForm
        data={selectedOrganization}
        setSelected={setSelectedOrganization}
        onSubmit={handleSubmit}
      />
    </>
  );
};

export default observer(OrganizationPage);
