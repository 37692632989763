import { mode } from '@chakra-ui/theme-tools';

export const radioStyles = {
  components: {
    Radio: {
      variants: {
        main: (props: any) => ({
          control: {
            _checked: {
              bg: mode('brand.500', 'brand.400')(props),
              borderColor: mode('brand.500', 'brand.400')(props),
            },
          },
        }),
      },
    },
  },
};
