import { Flex, Icon } from '@chakra-ui/react';

import { getIconAndColorByType } from '@/utils/tasks';

type Props = {
  type: TaskType;
};

const TaskType = ({ type }: Props) => {
  const data = getIconAndColorByType(type);

  return (
    <Flex align="center" justify="center" bg={data.color} borderRadius={5} p="5px">
      <Icon w="10px" h="10px" as={data.icon} color="white" />
    </Flex>
  );
};

export default TaskType;
