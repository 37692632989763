import { Droppable } from 'react-beautiful-dnd';
import { Card, Flex, IconButton, Text, useColorModeValue } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';

import TaskItem from './Item';

type Props = ColumnType & {
  tasks: Task[];
  admins: Record<string, Admin> | undefined;
  onTaskCreate: (data: Partial<Task>) => void;
};

const Column = ({ id, title, tasks, admins, onTaskCreate }: Props) => {
  const boxBg = useColorModeValue('whiteAlpha.500', 'whiteAlpha.100');

  const handleTaskCreate = () => {
    onTaskCreate({
      status: id,
      order: tasks.length,
    });
  };

  return (
    <Card
      key={id}
      bg={boxBg}
      maxW="450px"
      minW="250px"
      p={{
        base: '10px',
        md: '20px',
      }}
      overflowX="hidden"
      overflowY="auto"
    >
      <Flex
        justify="space-between"
        mb={{
          base: '0',
          md: '20px',
        }}
        p={{
          base: '10px',
          md: '0',
        }}
      >
        <Text fontWeight="bold">{title}</Text>

        <IconButton
          aria-label="Add Task"
          icon={<AddIcon />}
          size="xs"
          w="50px"
          h="30px"
          rounded="full"
          bg="brand.400"
          color="white"
          onClick={handleTaskCreate}
        />
      </Flex>

      <Droppable droppableId={id}>
        {(provided) => (
          <Flex
            h="100%"
            userSelect="none"
            direction="column"
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {tasks.map((task, i) => (
              <TaskItem
                onClick={() => onTaskCreate(task)}
                user={admins?.[task?.assignedTo ?? '']}
                index={i}
                key={task._id}
                task={task}
              />
            ))}
            {provided.placeholder}
          </Flex>
        )}
      </Droppable>
    </Card>
  );
};

export default Column;
