import {
  Flex,
  Stat,
  StatLabel,
  StatNumber,
  useColorModeValue,
  Text,
  useBreakpointValue,
  Card,
} from '@chakra-ui/react';

type Props = {
  startContent?: JSX.Element;
  endContent?: JSX.Element;
  name?: string;
  growth?: number | string;
  value?: string | number | null;
  mini?: boolean;
  hoverable?: boolean;
  hideTextOnMobile?: boolean;
  onClick?: () => void;
};

export default function MiniStats({
  startContent,
  endContent,
  name,
  growth,
  value,
  hoverable = false,
  mini = false,
  hideTextOnMobile = false,
  onClick,
}: Props) {
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'secondaryGray.600';
  const textCondition = useBreakpointValue({ base: !hideTextOnMobile, md: true });

  return (
    <Card
      py={mini ? '10px' : '20px'}
      px={mini ? '10px' : '20px'}
      pr={mini ? '10px' : '20px'}
      transition="all 0.2s ease"
      _hover={{
        opacity: hoverable ? 0.7 : 1,
        cursor: hoverable ? 'pointer' : 'inherit',
      }}
      onClick={onClick}
    >
      <Flex
        my="auto"
        h="100%"
        align={{ base: 'center', xl: 'start' }}
        justify={{ base: 'center', xl: 'center' }}
      >
        {startContent}

        <Stat my="auto">
          {name && textCondition && (
            <StatLabel
              lineHeight="100%"
              color={textColorSecondary}
              whiteSpace="nowrap"
              mx={mini ? '10px' : '20px'}
              fontSize={mini ? 'xs' : 'sm'}
            >
              {name}
            </StatLabel>
          )}

          {value && textCondition && (
            <StatNumber
              color={textColor}
              mx={mini ? '10px' : '20px'}
              fontSize={mini ? 'md' : '2xl'}
            >
              {value}
            </StatNumber>
          )}

          {growth ? (
            <Flex align="center" mx={mini ? '10px' : '20px'}>
              <Text color="green.500" fontSize="xs" fontWeight="700" me="5px">
                {growth}
              </Text>

              {typeof growth === 'number' && (
                <Text color="secondaryGray.600" fontSize="xs" fontWeight="400">
                  {growth > 0 ? '↑' : '↓'}
                </Text>
              )}
            </Flex>
          ) : null}
        </Stat>

        <Flex ms="auto" w="max-content">
          {endContent}
        </Flex>
      </Flex>
    </Card>
  );
}
