import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  ModalProps,
} from '@chakra-ui/react';

interface UniversalPopupProps extends Omit<ModalProps, 'children'> {
  title?: string;
  content: React.ReactNode;
  footer?: React.ReactNode;
}

const Popup: React.FC<UniversalPopupProps> = ({
  isOpen,
  onClose,
  title,
  content,
  footer,
  ...modalProps
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnEsc {...modalProps}>
      <ModalOverlay bg="rgba(0, 0, 0, 0.1)" backdropFilter="blur(4px)" />

      <ModalContent>
        {title && <ModalHeader>{title}</ModalHeader>}

        <ModalCloseButton />

        <ModalBody>{content}</ModalBody>

        {footer && <ModalFooter>{footer}</ModalFooter>}
      </ModalContent>
    </Modal>
  );
};

export default Popup;
