import {
  Box,
  Menu,
  MenuButton,
  Button,
  MenuList,
  MenuItem,
  Text,
  useColorModeValue,
  ButtonProps,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';

interface DropdownProps<T> {
  items: T[];
  disabled?: boolean;
  selectedItem: T | null;
  placeholder: string;
  labelKey: keyof T;
  extraLabelText?: string;
  valueKey: keyof T;
  width?: string | number;
  isTruncated?: boolean;
  menuVariant?: ButtonProps['variant'];
  bgActive?: string;
  bg?: string;
  colorActive?: string;
  listWidth?: string | number;
  emptyListText?: string;
  selectedColor?: string;
  icon?: React.ReactNode;
  translate?: boolean;
  onSelect: (item: T) => void;
}

const Dropdown = <T extends { [key: string]: any }>({
  items,
  disabled,
  selectedItem,
  placeholder,
  labelKey,
  valueKey,
  width = '100%',
  isTruncated = true,
  menuVariant = 'menu',
  listWidth,
  emptyListText,
  extraLabelText = '',
  selectedColor,
  icon,
  bg,
  translate = false,
  onSelect,
}: DropdownProps<T>) => {
  const { t } = useTranslation();
  const bgActive = useColorModeValue('gray.400', 'gray.600');
  const colorActive = useColorModeValue('white', 'white');

  const hasItems = !!items.length;

  return (
    <Box width={width}>
      <Menu>
        <MenuButton
          as={Button}
          rightIcon={<ChevronDownIcon />}
          variant={menuVariant}
          isDisabled={disabled}
          color={selectedColor}
          bg={bg}
          w="100%"
        >
          {icon}

          <Text isTruncated={isTruncated} w="100%" flexDirection="row">
            {selectedItem
              ? (translate ? t(selectedItem[labelKey]) : selectedItem[labelKey]) +
                extraLabelText
              : hasItems
              ? placeholder
              : emptyListText}
          </Text>
        </MenuButton>

        <MenuList overflow="hidden" w={listWidth} maxW={listWidth ?? 'max-content'}>
          {hasItems ? (
            items.map((item) => {
              const disabled = item.disabled || false;
              const isActive = selectedItem
                ? selectedItem[valueKey] === item[valueKey]
                : false;

              return (
                <MenuItem
                  isDisabled={disabled}
                  key={item[valueKey]}
                  onClick={() => onSelect(item)}
                  bg={isActive ? bgActive : 'transparent'}
                >
                  <Text
                    isTruncated={isTruncated}
                    color={isActive ? colorActive : 'inherit'}
                  >
                    {translate ? t(item[labelKey]) : item[labelKey]}
                  </Text>
                </MenuItem>
              );
            })
          ) : (
            <MenuItem isDisabled w={listWidth}>
              <Text>{emptyListText}</Text>
            </MenuItem>
          )}
        </MenuList>
      </Menu>
    </Box>
  );
};

export default Dropdown;
