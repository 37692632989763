import { request } from '@/utils/request';

export const getStripeList = () =>
  request<StripeAccountList>({
    url: '/stripe/list',
  });

export const removeStripeAcc = (id: string) =>
  request<'ok'>({
    url: '/stripe/remove',
    method: 'DELETE',
    param: id,
  });

export const stripeVerifyList = () =>
  request<StripeAccountList>({
    url: '/stripe/list/verify',
  });

export const getStripeLink = (body: StripeUrlRequest) =>
  request<StripeUrlResponse>({
    url: '/stripe/link',
    method: 'POST',
    body,
  });
