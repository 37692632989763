import {
  Stack,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Textarea,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const PropertyOther = () => {
  const { t } = useTranslation();

  const {
    formState: { errors },
    register,
  } = useFormContext<PropertyFormData>();

  return (
    <Stack spacing={4}>
      <Flex gap={{ base: 4, md: 2 }} direction="column">
        {/* Privacy URL */}
        <FormControl isRequired isInvalid={!!errors.privacyUrl}>
          <FormLabel>{t('property_form.privacy_url')}</FormLabel>

          <Input
            type="url"
            {...register('privacyUrl', { required: t('form.required') })}
          />

          <FormErrorMessage>{errors.privacyUrl?.message}</FormErrorMessage>
        </FormControl>

        {/* Comments */}
        <FormControl isInvalid={!!errors.comments}>
          <FormLabel>{t('property_form.comments')}</FormLabel>

          <Textarea maxLength={100} {...register('comments')} />

          <FormErrorMessage>{errors.comments?.message}</FormErrorMessage>
        </FormControl>
      </Flex>
    </Stack>
  );
};

export default PropertyOther;
