import { Box, Icon, useColorModeValue } from '@chakra-ui/react';
import { IconType } from 'react-icons';

import MiniStats from '../MiniStats';
import IconBox from '../Icons/IconBox';

type Props = {
  icon: IconType;
  iconColor?: string;
  mb?: string;
  name?: string;
  value?: string | number | null;
  hoverable?: boolean;
  mini?: boolean;
  hideTextOnMobile?: boolean;
  onClick?: () => void;
};

const ActionButton = ({
  icon,
  iconColor,
  mb,
  name,
  value,
  hoverable = true,
  mini = false,
  hideTextOnMobile = false,
  onClick,
}: Props) => {
  const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
  const brandColor = useColorModeValue('brand.500', 'white');

  return (
    <Box mb={mb} minW="auto">
      <MiniStats
        mini={mini}
        hoverable={hoverable}
        name={name}
        value={value}
        onClick={onClick}
        hideTextOnMobile={hideTextOnMobile}
        startContent={
          <IconBox
            w={mini ? '40px' : '46px'}
            h={mini ? '40px' : '46px'}
            bg={boxBg}
            icon={
              <Icon
                w={mini ? '20px' : '24px'}
                h={mini ? '20px' : '24px'}
                as={icon}
                color={iconColor ?? brandColor}
              />
            }
          />
        }
      />
    </Box>
  );
};

export default ActionButton;
