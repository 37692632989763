import { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Badge, Flex, HStack, IconButton, Text } from '@chakra-ui/react';
import { FaHome, FaPencilAlt, FaTrash } from 'react-icons/fa';
import { IoAdd, IoReload } from 'react-icons/io5';

import DataTable from '@/components/Table';
import { getProperties, deleteProperty } from '@/api/property';
import Pagination from '@/components/Pagination';
import usePageInfo from '@/hooks/usePageInfo';
import usePagination from '@/hooks/usePagination';
import useRequestState from '@/hooks/useRequestState';
import ActionButton from '@/components/ActionButton';
import AppStore from '@/stores/app';
import PropertyForm from './components/form';
import ConfirmDialog from '@/components/Dialogs/ConfirmDialog';
import ActionBar from '@/components/ActionBar';

const PropertiesPage = () => {
  usePageInfo({ title: 'pages.properties' });

  const { t } = useTranslation();
  const { setProperties } = AppStore;

  const [selectedProperty, setSelectedProperty] = useState<Property | null | undefined>(
    undefined,
  );
  const [toDelete, setTodelete] = useState<Property | null>(null);

  const { page, limit, sortBy, order, setPage, toggleOrder, setLimit } =
    usePagination<Property>('name');

  const handleDialogToggle = useCallback((data?: Property | null) => {
    setTodelete(data ?? null);
  }, []);

  const { data, trigger: dataTrigger } = useRequestState<PaginateResult<Property>>(
    () => getProperties({ page, limit, sortBy, order }),
    [page, limit, sortBy, order],
  );

  const onDeleteSuccess = useCallback(() => {
    dataTrigger();
    handleDialogToggle(null);
  }, [dataTrigger, handleDialogToggle]);

  const { trigger: deleteTrigger } = useRequestState<'ok'>(
    () => deleteProperty(toDelete?._id!),
    [toDelete],
    { condition: false, onSuccess: onDeleteSuccess },
  );

  useEffect(() => {
    if (data?.docs) {
      setProperties(data.docs);
    }
  }, [data, setProperties]);

  const handleSubmit = useCallback(() => {
    dataTrigger();
  }, [dataTrigger]);

  return (
    <>
      <Flex direction="column" h="100%">
        <ActionBar
          stats={
            <ActionButton
              mini
              hoverable={false}
              name={t('properties.total')}
              value={`${data?.totalDocs ?? '-'}`}
              icon={FaHome}
            />
          }
          actions={
            <>
              <ActionButton mini icon={IoAdd} onClick={() => setSelectedProperty(null)} />

              <ActionButton mini icon={IoReload} onClick={dataTrigger} />
            </>
          }
        />

        <DataTable<Property, 'city' | 'actions'>
          mt="20px"
          sortBy={sortBy}
          order={order}
          onSort={toggleOrder}
          data={data?.docs || []}
          columns={[
            {
              id: 'name',
              header: t('properties.name'),
              accessor: 'name',
              isSortable: true,
            },
            {
              id: 'isActive',
              header: t('properties.active'),
              accessor: 'isActive',
              isSortable: true,
              cell: (property: Property) => (
                <Badge colorScheme={property.isActive ? 'green' : 'red'}>
                  {property.isActive ? 'Active' : 'Inactive'}
                </Badge>
              ),
            },
            {
              id: 'city',
              header: t('properties.city'),
              accessor: 'address.city',
              isSortable: true,
            },
            {
              id: 'address',
              header: t('properties.address'),
              accessor: 'address',
              cell: (property: Property) => (
                <Text>
                  {property.address.street} {property.address.building}/
                  {property.address.unit}
                </Text>
              ),
            },
            {
              id: 'currency',
              header: t('properties.currency'),
              accessor: 'currency',
              isSortable: true,
              center: true,
            },
            {
              id: 'paymentShiftDay',
              header: t('properties.payment_shift'),
              accessor: 'paymentShiftDay',
              isSortable: true,
              center: true,
            },
            {
              id: 'invoiceExpirationDays',
              header: t('properties.invoice_expiration'),
              accessor: 'invoiceExpirationDays',
              isSortable: true,
              center: true,
            },
            {
              id: 'comments',
              header: t('properties.comments'),
              accessor: 'comments',
              maxW: '100px',
            },
            {
              id: 'actions',
              header: t('properties.actions'),
              accessor: null,
              center: true,
              cell: (data: Property) => (
                <HStack justify="center">
                  <IconButton
                    aria-label="Update"
                    size="sm"
                    icon={<FaPencilAlt />}
                    onClick={() => setSelectedProperty(data)}
                  />

                  <IconButton
                    aria-label="Delete"
                    size="sm"
                    icon={<FaTrash />}
                    onClick={() => handleDialogToggle(data)}
                  />
                </HStack>
              ),
            },
          ]}
          pagination={
            <Pagination
              {...data}
              onPageSizeChange={setLimit}
              onPageChange={setPage}
              limit={limit}
            />
          }
        />
      </Flex>

      <PropertyForm
        data={selectedProperty}
        setSelected={setSelectedProperty}
        onSubmit={handleSubmit}
      />

      <ConfirmDialog
        isOpen={!!toDelete}
        title={t('units.delete_confirmation_title')}
        body={t('units.delete_confirmation_body', {
          name: `${toDelete?.name}`,
        })}
        onConfirm={deleteTrigger}
        onCancel={() => handleDialogToggle()}
      />
    </>
  );
};

export default observer(PropertiesPage);
