import { useCallback, useEffect, useState } from 'react';
import { Input, InputGroup, InputLeftElement, useColorModeValue } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { SearchIcon } from '@chakra-ui/icons';

type Props = {
  initialValue?: string;
  onChange: (value: string) => void;
  delay?: number;
  placeholder?: string;
  mini?: boolean;
  reverseColor?: boolean;
  w?: string;
  maxW?: string;
};

const Search = ({
  initialValue = '',
  onChange,
  delay = 500,
  placeholder,
  mini,
  reverseColor,
  w,
  maxW,
}: Props) => {
  const [value, setValue] = useState(initialValue);
  const { t } = useTranslation();

  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.300');
  const color = useColorModeValue('gray.500', 'whiteAlpha.600');

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  }, []);

  useEffect(() => {
    const handler = setTimeout(() => {
      onChange(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, onChange, delay]);

  return (
    <InputGroup w={w}>
      <InputLeftElement
        pointerEvents="none"
        h={mini ? '35px' : '60px'}
        w={mini ? '35px' : '60px'}
      >
        <SearchIcon
          fontSize={mini ? 'md' : 'lg'}
          color={reverseColor ? color : undefined}
        />
      </InputLeftElement>

      <Input
        width="100%"
        value={value}
        variant="search"
        onChange={handleChange}
        height={mini ? '35px' : '60px'}
        border={reverseColor ? `1px solid` : 'none'}
        borderColor={reverseColor ? borderColor : 'transparent'}
        borderRadius={mini ? '8px' : undefined}
        maxW={maxW}
        pl={mini ? '35px' : '60px'}
        placeholder={t(placeholder ?? 'common.search')}
      />
    </InputGroup>
  );
};

export default Search;
