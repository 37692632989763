import { Logo } from '@/components/Icons/Logo';
import { Flex, useColorModeValue } from '@chakra-ui/react';

export function SidebarBrand() {
  const color = useColorModeValue('brand.600', 'gray.100');

  return (
    <Flex flexDirection="column" py="10px" alignItems="center">
      <Logo width="100%" height="55px" fill={color} />
    </Flex>
  );
}

export default SidebarBrand;
