import { request } from '@/utils/request';

export const getProfile = async () =>
  request<Profile>({
    url: '/admin/profile',
  });

export const getUsers = async (query: PaginationRequestType) =>
  request<PaginateResult<Admin>>({
    url: '/admin',
    query,
  });

export const createUser = async (body: UserFormData) =>
  request<Admin>({
    url: '/admin',
    method: 'POST',
    body,
  });

export const updateUser = async (body: Partial<UserFormData>) =>
  request<Admin>({
    url: '/admin',
    method: 'PUT',
    body,
    param: body._id,
  });

export const deleteUser = async (id: string) =>
  request<'ok'>({
    url: '/admin',
    method: 'DELETE',
    param: id,
  });

export const switchOrganization = async (organization: Organization['_id']) =>
  request<{ token: string }>({
    url: '/admin/switch-org',
    method: 'POST',
    body: { organization },
  });
