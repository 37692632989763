import { request } from '@/utils/request';

export const getTasks = async (assignedTo?: string | null) =>
  request<TaskListResponse>({
    url: '/tasks',
    query: { assignedTo },
  });

export const createTask = async (body: Partial<Task>) =>
  request<Task>({
    url: '/tasks',
    method: 'POST',
    body,
  });

export const updateTask = async (id: string, body: Partial<Task>) =>
  request<Task>({
    url: '/tasks',
    method: 'PUT',
    body,
    param: id,
  });

export const reorderTasks = async (body: ReorderedTask[]) =>
  request<'ok'>({
    url: '/tasks/reorder',
    method: 'POST',
    body,
  });

export const deleteTask = async (id: string) =>
  request<'ok'>({
    url: '/tasks',
    method: 'DELETE',
    param: id,
  });
