import { request } from '@/utils/request';

export const propertyStats = async ({
  startDate,
  endDate,
}: {
  startDate: Date;
  endDate: Date;
}) =>
  request<PropertyStats>({
    url: '/stats/property',
    method: 'POST',
    body: {
      startDate,
      endDate,
    },
  });
