import { request } from '@/utils/request';

export const getComplains = async (query: PaginationRequestType) =>
  request<PaginateResult<Complain>>({
    url: '/complain',
    query,
  });

export const replyComplain = async (id: string, body: ComplainFormData) =>
  request<'ok'>({
    url: '/complain/reply',
    method: 'POST',
    param: id,
    body,
  });

export const changeStatus = async (id: string, status: ComplainStatus) =>
  request<'ok'>({
    url: '/complain/status',
    method: 'PUT',
    param: id,
    body: { status },
  });
