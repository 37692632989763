import { useState } from 'react';

import { PAGINATION_DEFAULT_LIMIT } from '@/constants/app';

interface PaginationAndSorting<T, E> {
  page: number;
  sortBy: keyof T | E;
  limit: PaginationLimits;
  order: 'asc' | 'desc';
  search: string;
  setPage: (newPage: number) => void;
  toggleOrder: (field: keyof T | E) => void;
  setLimit: (newLimit: PaginationLimits) => void;
  setSearch: (newSearch: string) => void;
}

const usePaginationAndSorting = <T, E = any>(
  initialSortBy: keyof T | E,
  initialOrder: PaginationAndSorting<T, E>['order'] = 'asc',
  initialPage: number = 1,
  _limit: PaginationLimits = PAGINATION_DEFAULT_LIMIT,
): PaginationAndSorting<T, E> => {
  const [page, setPage] = useState(initialPage);
  const [sortBy, setSortBy] = useState(initialSortBy);
  const [order, setOrder] = useState(initialOrder);
  const [limit, setLimit] = useState(_limit);
  const [search, setSearch] = useState('');

  const toggleOrder = (field: keyof T | E) => {
    if (field !== sortBy) {
      setSortBy(field);
      setPage(1);
      setOrder('asc');

      return;
    }

    setOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
  };

  return {
    page,
    sortBy,
    order,
    limit,
    search,
    setPage,
    setLimit,
    toggleOrder,
    setSearch,
  };
};

export default usePaginationAndSorting;
