import { Flex, SimpleGrid } from '@chakra-ui/react';
import Search from '../Search';

type Props = {
  stats?: React.ReactNode;
  actions?: React.ReactNode;
  setSearch?: (value: string) => void;
};

const ActionBar = ({ actions, stats, setSearch }: Props) => {
  return (
    <SimpleGrid
      gap="20px"
      columns={{
        base: 1,
        md: 2,
      }}
    >
      <Flex gap="20px">
        {stats}

        <Flex
          gap="20px"
          ml="auto"
          display={{
            base: 'flex',
            md: 'none',
          }}
        >
          {actions}
        </Flex>
      </Flex>

      <Flex
        gap="20px"
        direction={{
          base: 'column-reverse',
          md: 'row',
        }}
      >
        {setSearch && <Search onChange={setSearch} />}

        <Flex
          flex={1}
          gap="20px"
          display={{
            base: 'none',
            md: 'flex',
          }}
          align="flex-end"
          justify="flex-end"
        >
          {actions}
        </Flex>
      </Flex>
    </SimpleGrid>
  );
};

export default ActionBar;
