import { observable, action, makeObservable } from 'mobx';

class LayoutClass {
  menuOpen = false;
  pageTitle = '';

  constructor() {
    makeObservable(this, {
      pageTitle: observable,
      menuOpen: observable,
      setPageInfo: action,
      setMenuOpen: action,
    });
  }

  setMenuOpen = (value: boolean) => {
    this.menuOpen = value;
  };

  setPageInfo = ({ title }: { title: string }) => {
    this.pageTitle = title;
    document.title = title;
  };
}

const LayoutStore = new LayoutClass();

export default LayoutStore;
