import { request } from '@/utils/request';

export const getUnits = async (
  query: PaginationRequestType = {
    page: 1,
    limit: 100,
    sortBy: 'name',
    order: 'asc',
  },
) =>
  request<PaginateResult<Unit>>({
    url: '/unit',
    query,
  });

export const createUnit = async (body: UnitFormData) =>
  request<Unit>({
    url: '/unit',
    method: 'POST',
    body,
  });

export const updateUnit = async (body: UnitFormData) =>
  request<Unit>({
    url: '/unit',
    method: 'PUT',
    body,
    param: body._id,
  });

export const deleteUnit = async (id: string) =>
  request<'ok'>({
    url: '/unit',
    method: 'DELETE',
    param: id,
  });
