import { request } from '@/utils/request';

export const getAnnouncements = async (query: PaginationRequestType) =>
  request<PaginateResult<Announcement>>({
    url: '/announcement',
    query,
  });

export const createAnnouncement = async (body: AnnouncementFormData) =>
  request<Announcement>({
    url: '/announcement',
    method: 'POST',
    body,
  });

export const updateAnnouncement = async (id: string, body: AnnouncementFormData) =>
  request<Announcement>({
    url: `/announcement`,
    method: 'PUT',
    param: id,
    body,
  });

export const deleteAnnouncement = async (id: Announcement['_id']) =>
  request<'ok'>({
    url: '/announcement',
    method: 'DELETE',
    param: id,
  });

export const notifyUsers = async (id: Announcement['_id']) =>
  request<{ success: number; failed: number }>({
    url: '/announcement/notify',
    param: id,
  });
