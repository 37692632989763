import { DropResult } from 'react-beautiful-dnd';
import { FaCheck, FaExclamationTriangle } from 'react-icons/fa';
import { IconType } from 'react-icons';

import { TASK_TYPE } from '@/constants/app';

const handleDragEnd = (
  result: DropResult,
  tasks: TaskListResponse,
): {
  updatedTasks: TaskListResponse;
  reorderedItems: ReorderedTask[];
} => {
  const { source, destination } = result;

  // If the destination is null (dropped outside a list), do nothing
  if (!destination) return { updatedTasks: tasks, reorderedItems: [] };

  const sourceList = tasks[source.droppableId as TaskStatus];
  const destinationList = tasks[destination.droppableId as TaskStatus];

  // Clone the tasks to avoid direct mutation
  const updatedTasks: TaskListResponse = { ...tasks };

  let reorderedItems: ReorderedTask[] = [];

  // Moving within the same list
  if (source.droppableId === destination.droppableId) {
    const reorderedTasks = Array.from(sourceList);
    const [movedTask] = reorderedTasks.splice(source.index, 1);
    reorderedTasks.splice(destination.index, 0, movedTask);

    // Update orders in the modified list and add the updated status
    reorderedItems = reorderedTasks.map((task, index) => ({
      _id: task._id,
      order: index,
      status: source.droppableId as TaskStatus,
    }));

    updatedTasks[source.droppableId as TaskStatus] = reorderedTasks;
  } else {
    // Moving between lists
    const sourceTasks = Array.from(sourceList);
    const destinationTasks = Array.from(destinationList);

    const [movedTask] = sourceTasks.splice(source.index, 1);
    movedTask.status = destination.droppableId as TaskStatus; // Update status of moved task
    destinationTasks.splice(destination.index, 0, movedTask);

    // Update orders in both modified lists and add the updated status
    reorderedItems = [
      ...sourceTasks.map((task, index) => ({
        _id: task._id,
        order: index,
        status: source.droppableId as TaskStatus,
      })),
      ...destinationTasks.map((task, index) => ({
        _id: task._id,
        order: index,
        status: destination.droppableId as TaskStatus,
      })),
    ];

    updatedTasks[source.droppableId as TaskStatus] = sourceTasks;
    updatedTasks[destination.droppableId as TaskStatus] = destinationTasks;
  }

  return { updatedTasks, reorderedItems };
};

export const getIconAndColorByType = (
  status: TaskType,
): { icon: IconType; color: string } => {
  switch (status) {
    case TASK_TYPE.BUG:
      return { icon: FaExclamationTriangle, color: 'red.400' };
    case TASK_TYPE.TASK:
      return { icon: FaCheck, color: 'green.500' };
    default:
      return { icon: FaCheck, color: 'green.500' };
  }
};

export default handleDragEnd;
