import { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Text,
  Spinner,
  Input,
  useToast,
} from '@chakra-ui/react';
import OtpInput from 'react-otp-input';
import { FcGoogle } from 'react-icons/fc';
import { useTranslation } from 'react-i18next';
import { useGoogleLogin } from '@react-oauth/google';

import { HSeparator } from '@/components/Separator';
import { login, sendCode, googleLogin } from '@/api/auth';
import AuthStore from '@/stores/auth';
import { OTP_LENGTH } from '@/constants/app';
import useRequestState from '@/hooks/useRequestState';

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [isCodeSent, setIsCodeSent] = useState(false);

  const { t } = useTranslation();
  const toast = useToast();

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: (token) => googleLoginTrigger({ token: token.access_token }),
    onError: () => {
      toast({
        description: t('login.google_error'),
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    },
    flow: 'implicit',
  });

  const onLogin = useCallback(async (data: LoginResonse) => {
    AuthStore.setToken(data.token ?? null);
  }, []);

  const onSendCode = useCallback(() => {
    setIsCodeSent(true);
  }, []);

  const { trigger: sendCodeTrigger, loading: codeLoading } = useRequestState<'ok'>(
    () => sendCode(email),
    [email],
    { condition: false, onSuccess: onSendCode },
  );

  const { trigger: loginTrigger, loading: loginLoading } = useRequestState<LoginResonse>(
    () => login(email, code),
    [email, code],
    {
      condition: false,
      onSuccess: onLogin,
    },
  );

  const { trigger: googleLoginTrigger, loading: googleLoading } = useRequestState<
    LoginResonse,
    { token: string }
  >((data) => googleLogin(data!), [], {
    condition: false,
    onSuccess: onLogin,
  });

  useEffect(() => {
    if (code.length === OTP_LENGTH) {
      loginTrigger();
    }
  }, [code, loginTrigger]);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleCodeChange = (value: string) => {
    setCode(value);
  };

  const handleCancel = () => {
    setIsCodeSent(false);
    setCode('');
  };

  const loading = codeLoading || loginLoading || googleLoading;

  return (
    <Flex
      w="100%"
      maxW={{ base: '100%', md: 450 }}
      mt={{ base: '50px', md: '150px' }}
      px={{ base: '25px', md: '0px' }}
      flexDirection="column"
    >
      <Box me="auto">
        <Heading fontSize="36px" mb="10px">
          {t('login.title')}
        </Heading>

        <Text mb="36px" ms="4px" fontWeight="400" fontSize="md">
          {t('login.enter_email')}
        </Text>
      </Box>

      <Flex direction="column" w="100%" me="auto">
        <Button
          fontSize="sm"
          me="0px"
          mb="26px"
          py="15px"
          h="50px"
          fontWeight="500"
          onClick={() => handleGoogleLogin()}
        >
          <Icon as={FcGoogle} w="20px" h="20px" me="10px" />

          {t('login.sing_in_google')}
        </Button>

        <Flex align="center" mb="25px">
          <HSeparator />

          <Text color="gray.400" mx="14px">
            {t('login.or')}
          </Text>

          <HSeparator />
        </Flex>

        <FormControl>
          <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" mb="16px">
            {!isCodeSent ? t('login.email') : t('login.code_sent_to', { email })}
          </FormLabel>

          {!isCodeSent && (
            <Input
              isRequired
              fontSize="sm"
              type="email"
              placeholder="mail@example.com"
              mb="24px"
              size="lg"
              onChange={handleEmailChange}
              disabled={loading}
            />
          )}

          {!isCodeSent && (
            <Button
              fontSize="sm"
              variant="brand"
              fontWeight="500"
              w="100%"
              h="50"
              mb="24px"
              onClick={sendCodeTrigger}
            >
              {loading ? <Spinner /> : t('login.send_code')}
            </Button>
          )}

          {isCodeSent && (
            <Flex w="100%" align="center" justify="center" mb="24px" flex={1}>
              <OtpInput
                value={code}
                onChange={handleCodeChange}
                numInputs={OTP_LENGTH}
                shouldAutoFocus
                skipDefaultStyles
                containerStyle={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
                renderInput={(props) => (
                  <Input
                    w="50px"
                    h="50px"
                    mx="15px"
                    variant="auth"
                    textAlign="center"
                    isDisabled={loading}
                    {...props}
                  />
                )}
              />
            </Flex>
          )}

          {isCodeSent && (
            <Button
              fontSize="sm"
              variant="solid"
              fontWeight="500"
              w="100%"
              h="50"
              mb="24px"
              disabled={loading}
              onClick={handleCancel}
            >
              {loading ? <Spinner /> : t('login.back')}
            </Button>
          )}
        </FormControl>
      </Flex>
    </Flex>
  );
};

export default LoginPage;
