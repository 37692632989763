import { Icon } from '@chakra-ui/react';
import { MdPeople, MdAutoGraph, MdHouse, MdReport } from 'react-icons/md';
import {
  FaFileContract,
  FaTools,
  FaDatabase,
  FaPeopleCarry,
  FaTasks,
  FaHospitalUser,
} from 'react-icons/fa';
import { IoGridOutline } from 'react-icons/io5';
import { GrAnnounce } from 'react-icons/gr';
import { TbReceipt2 } from 'react-icons/tb';

import { ROUTES } from '@/constants/routes';

const LINKS = [
  {
    name: 'sidebar.dashboard',
    path: ROUTES.DASHBOARD,
    icon: <Icon as={MdAutoGraph} width="25px" height="22px" color="inherit" />,
  },
  {
    name: 'sidebar.units',
    path: ROUTES.UNITS,
    icon: <Icon as={IoGridOutline} width="25px" height="20px" color="inherit" />,
  },
  {
    name: 'sidebar.contracts',
    path: ROUTES.CONTRACTS,
    icon: <Icon as={FaFileContract} width="25px" height="20px" color="inherit" />,
  },
  {
    name: 'sidebar.clients',
    path: ROUTES.CLIENTS,
    icon: <Icon as={FaPeopleCarry} width="25px" height="18px" color="inherit" />,
  },
  {
    name: 'sidebar.invoices',
    path: ROUTES.PAYMENTS,
    icon: <Icon as={TbReceipt2} width="25px" height="23px" color="inherit" />,
  },
  {
    name: 'sidebar.announcements',
    path: ROUTES.ANNOUNCEMENTS,
    icon: <Icon as={GrAnnounce} width="25px" height="20px" color="inherit" />,
  },
  {
    name: 'sidebar.tasks',
    path: ROUTES.TASKS,
    icon: <Icon as={FaTasks} width="25px" height="17px" color="inherit" />,
  },
];

export const LINKS_ADMIN = [
  {
    name: 'sidebar.complains',
    path: ROUTES.COMPLAINS,
    icon: <Icon as={MdReport} width="25px" height="22px" mt="0px" color="inherit" />,
  },
  {
    name: 'sidebar.users',
    path: ROUTES.USERS,
    icon: <Icon as={MdPeople} width="25px" height="22px" color="inherit" />,
  },
  {
    name: 'sidebar.properties',
    path: ROUTES.OBJECTS,
    icon: <Icon as={MdHouse} width="25px" height="22px" color="inherit" />,
  },
  {
    name: 'sidebar.settings',
    path: ROUTES.SETTINGS,
    icon: <Icon as={FaTools} width="25px" height="17px" color="inherit" />,
  },
  {
    name: 'sidebar.import/export',
    path: ROUTES.IMPORT_EXPORT,
    icon: <Icon as={FaDatabase} width="25px" height="17px" color="inherit" />,
  },
];

export const LINKS_SUPER_ADMIN = [
  {
    name: 'sidebar.organizations',
    path: ROUTES.ORGANIZATIONS,
    icon: <Icon as={FaHospitalUser} width="25px" height="17px" color="inherit" />,
  },
];

export default LINKS;
