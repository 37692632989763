import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Box, useColorModeValue } from '@chakra-ui/react';

import AppStore from '@/stores/app';
import { useMemo } from 'react';
import Dropdown from '@/components/DropDown';

const PropertySelector = () => {
  const { properties, selectedProperty, setSelectedProperty } = AppStore;

  const { t } = useTranslation();
  const color = useColorModeValue('brand.300', 'gray.300');

  const propertyList = useMemo(() => Object.values(properties), [properties]);

  const handleSelect = (property: Property) => {
    setSelectedProperty(property._id);
  };

  const isPropertyInactive = properties[selectedProperty!]?.isActive === false;

  return (
    <Box p="10px">
      <Dropdown<Property>
        items={propertyList}
        selectedItem={properties[selectedProperty!] ?? null}
        onSelect={handleSelect}
        placeholder={t('sidebar.select_property')}
        emptyListText={t('sidebar.no_properties')}
        labelKey="name"
        extraLabelText={isPropertyInactive ? ` [${t('sidebar.inactive')}]` : ''}
        valueKey="_id"
        listWidth="278px"
        selectedColor={color}
      />
    </Box>
  );
};

export default observer(PropertySelector);
