import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { useForm } from 'react-hook-form';
import {
  Stack,
  FormControl,
  FormLabel,
  Input,
  Button,
  HStack,
  FormErrorMessage,
  Flex,
  Textarea,
  Select,
} from '@chakra-ui/react';

import Popup from '@/components/Popup';
import useRequestState from '@/hooks/useRequestState';
import { createTask, deleteTask, updateTask } from '@/api/task';
import AuthStore from '@/stores/auth';
import { TASK_TYPE } from '@/constants/app';
import { isAdmin } from '@/utils/admin';

export const DEFAULT_FORM_DATA: Partial<Task> = {
  title: '',
  description: '',
  assignedTo: null,
  price: undefined,
};

const placeHolderAssignedTo = '---';

type Props = {
  data: Partial<Task> | null | undefined;
  admins?: Record<string, Admin>;
  setSelected: (data: Task | null | undefined) => void;
  onSubmit: () => void;
};

const CreateTaskForm = ({ data, admins, setSelected, onSubmit }: Props) => {
  const { t } = useTranslation();
  const role = AuthStore.profile?.role;

  const {
    register,
    getValues,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Partial<Task>>({
    defaultValues: DEFAULT_FORM_DATA,
    values: data ?? DEFAULT_FORM_DATA,
  });

  const handleOnClose = (data?: null | undefined) => {
    reset();
    setSelected(data);
  };

  const handleSuccess = () => onSubmit();

  const { trigger: deleteTrigger, loading: deleteLoading } = useRequestState<'ok'>(
    () => deleteTask(data?._id),
    [],
    {
      condition: false,
      onSuccess: handleSuccess,
    },
  );

  const { loading: submitLoading, trigger: submitTrigger } = useRequestState<Task>(
    () => {
      const body = getValues();
      body.assignedTo =
        body.assignedTo === placeHolderAssignedTo ? null : body.assignedTo;

      if (data?._id) {
        return updateTask(data?._id, body);
      }

      return createTask(body);
    },
    [],
    { condition: false, onSuccess: handleSuccess },
  );

  const loading = deleteLoading || submitLoading;

  return (
    <Popup
      size="lg"
      isOpen={data !== undefined}
      onClose={() => handleOnClose(undefined)}
      title={data?._id ? t('tasks_form.create') : t('tasks_form.edit')}
      content={
        <form>
          <Stack spacing={4}>
            <Flex
              direction={{ base: 'column', md: 'row' }}
              gap={{
                base: 2,
                md: 5,
              }}
            >
              {/* Title */}
              <FormControl isRequired isInvalid={!!errors.title}>
                <FormLabel>{t('tasks_form.title')}</FormLabel>

                <Input {...register('title', { required: t('form.required') })} />

                <FormErrorMessage>{errors.title?.message}</FormErrorMessage>
              </FormControl>
            </Flex>

            {/* Message */}
            <FormControl isRequired isInvalid={!!errors.description}>
              <FormLabel>{t('tasks_form.description')}</FormLabel>

              <Textarea
                maxLength={500}
                {...register('description', { required: t('form.required') })}
              />

              <FormErrorMessage>{errors.description?.message}</FormErrorMessage>
            </FormControl>

            <HStack>
              {/* Type */}
              <FormControl isRequired isInvalid={!!errors.type}>
                <FormLabel>{t('tasks_form.type')}</FormLabel>

                <Select
                  disabled={loading}
                  {...register('type', { required: t('form.required') })}
                >
                  {Object.values(TASK_TYPE).map((data) => (
                    <option key={data} value={data}>
                      {t(`tasks.types.${data}`)}
                    </option>
                  ))}
                </Select>

                <FormErrorMessage>{errors.type?.message}</FormErrorMessage>
              </FormControl>

              {/* Price */}
              <FormControl isInvalid={!!errors.price}>
                <FormLabel>{t('tasks_form.price')}</FormLabel>

                <Input type="number" {...register('price')} />

                <FormErrorMessage>{errors.price?.message}</FormErrorMessage>
              </FormControl>
            </HStack>

            {/* Assigned To */}
            <FormControl isRequired isInvalid={!!errors.assignedTo}>
              <FormLabel>{t('tasks_form.assigned_to')}</FormLabel>

              <Select
                disabled={loading}
                {...register('assignedTo', { required: t('form.required') })}
              >
                <option value={placeHolderAssignedTo}>{t('tasks.not_assigned')}</option>

                {Object.values(admins ?? []).map((data) => (
                  <option key={data._id} value={data._id}>
                    {data.name} {data.lastName}
                  </option>
                ))}
              </Select>

              <FormErrorMessage>{errors.assignedTo?.message}</FormErrorMessage>
            </FormControl>
          </Stack>
        </form>
      }
      footer={
        <HStack>
          <Button
            disabled={loading}
            type="submit"
            variant="brand"
            onClick={handleSubmit(submitTrigger)}
          >
            {data?._id ? t('form.update') : t('form.save')}
          </Button>

          {isAdmin(role) && data?._id && (
            <Button
              disabled={loading}
              colorScheme="red"
              onClick={() => deleteTrigger(data._id)}
            >
              {t('form.delete')}
            </Button>
          )}

          <Button
            disabled={loading}
            variant="outline"
            onClick={() => handleOnClose(undefined)}
          >
            {t('form.cancel')}
          </Button>
        </HStack>
      }
    />
  );
};

export default observer(CreateTaskForm);
