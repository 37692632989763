import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';

type ChartProps = {
  chartData: any[];
  chartOptions: any;
  h?: string;
  w?: string;
};

const PieChart: React.FC<ChartProps> = ({
  chartData,
  chartOptions,
  h = '55%',
  w = '100%',
}) => {
  const [data, setData] = useState(chartData);
  const [options, setOptions] = useState(chartOptions);

  useEffect(() => {
    setData(chartData);
    setOptions(chartOptions);
  }, [chartData, chartOptions]);

  return (
    <ReactApexChart options={options} series={data} type="pie" width={w} height={h} />
  );
};

export default PieChart;
