import { Box, Flex, Text } from '@chakra-ui/react';

import LineChart from '@/components/Charts/LineChart';
import { lineChartOptionsTotalSpent } from '@/components/Charts/config';

type Props = {
  data: {
    name: string;
    data: number[];
  }[];
  title?: string;
};

export default function TotalSpent({ data, title }: Props) {
  return (
    <Flex flexDirection="column">
      <Flex flexDirection="column">
        <Text
          fontSize="xl"
          textAlign="start"
          fontWeight="700"
          lineHeight="100%"
          whiteSpace="nowrap"
        >
          {title}
        </Text>
      </Flex>

      <Box h="250px" w="100%" minW="100%">
        <LineChart chartData={data} chartOptions={lineChartOptionsTotalSpent} />
      </Box>
    </Flex>
  );
}
