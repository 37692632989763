import { CURRENCY, INVOICE_EXPIRATION_DAYS, PAYMENT_SHIFT_DAY } from '@/constants/app';

export const DEFAULT_FORM_DATA: PropertyFormData = {
  _id: '',
  name: '',
  organization: '',
  phone: '',
  email: '',
  address: {
    street: '',
    building: '',
    unit: '',
    city: '',
    state: '',
    zip: '',
    country: '',
  },
  currency: CURRENCY.USD,
  paymentShiftDay: PAYMENT_SHIFT_DAY.DEFAULT,
  invoiceExpirationDays: INVOICE_EXPIRATION_DAYS,
  stripe: '',
  isActive: true,
  privacyUrl: '',
  comments: '',
  createdAt: new Date(),
  updatedAt: new Date(),
};
