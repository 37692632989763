import { observer } from 'mobx-react-lite';

import usePageInfo from '@/hooks/usePageInfo';
import InvoiceTable from './components/InvoiceTable';

const InvoicePage = () => {
  usePageInfo({ title: 'pages.invoices' });

  return <InvoiceTable />;
};

export default observer(InvoicePage);
