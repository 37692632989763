import { request } from '@/utils/request';

export const sendCode = async (email: string) =>
  request<'ok'>({
    url: '/auth/code',
    method: 'POST',
    body: { email },
  });

export const login = async (email: string, code: string) =>
  request<LoginResonse>({
    url: '/auth/login',
    method: 'POST',
    body: { email, code },
  });

export const googleLogin = async ({ token }: { token: string }) =>
  request<LoginResonse>({
    url: '/auth/google',
    method: 'POST',
    body: { token },
  });
