import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { useForm } from 'react-hook-form';
import {
  Stack,
  FormControl,
  FormLabel,
  Input,
  Button,
  HStack,
  FormErrorMessage,
  Checkbox,
  Textarea,
  Flex,
} from '@chakra-ui/react';

import Popup from '@/components/Popup';
import useRequestState from '@/hooks/useRequestState';
import { createUnit, updateUnit } from '@/api/unit';
import { DEFAULT_FORM_DATA } from './config';

type Props = {
  data: Unit | null | undefined;
  setSelected: (data: Unit | null | undefined) => void;
  onSubmit: (data: Unit) => void;
};

const UnitForm = ({ data, setSelected, onSubmit }: Props) => {
  const { t } = useTranslation();
  const [addMore, setAddMore] = useState(false);

  const {
    register,
    handleSubmit,
    getValues,
    reset,
    formState: { errors },
  } = useForm<UnitFormData | Unit>({
    defaultValues: data ?? DEFAULT_FORM_DATA,
    values: data ?? DEFAULT_FORM_DATA,
  });

  const handleOnClose = (data?: null | undefined) => {
    reset();
    setAddMore(data === null);
    setSelected(data);
  };

  const handleSuccess = (data: Unit) => {
    onSubmit(data);
    handleOnClose(addMore ? null : undefined);
  };

  const { loading, trigger: submitTrigger } = useRequestState<Unit>(
    () => {
      const body = getValues();

      const method = data ? updateUnit : createUnit;

      return method({ ...body, organization: undefined, property: undefined });
    },
    [],
    { condition: false, onSuccess: handleSuccess },
  );

  return (
    <Popup
      size="xl"
      isOpen={data !== undefined}
      onClose={() => handleOnClose(undefined)}
      title={data?._id ? t('unit_form.edit_unit') : t('unit_form.create_unit')}
      content={
        <form>
          <Stack spacing={4}>
            {/* Name */}
            <FormControl isRequired isInvalid={!!errors.name}>
              <FormLabel>{t('unit_form.name')}</FormLabel>
              <Input {...register('name', { required: t('form.required') })} />
              <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
            </FormControl>

            <Flex
              direction={{ base: 'column', md: 'row' }}
              gap={{
                base: 2,
                md: 5,
              }}
            >
              {/* Deposit */}
              <FormControl isRequired isInvalid={!!errors.deposit}>
                <FormLabel>{t('unit_form.deposit')}</FormLabel>
                <Input
                  type="number"
                  {...register('deposit', { required: t('form.required') })}
                />
                <FormErrorMessage>{errors.deposit?.message}</FormErrorMessage>
              </FormControl>

              {/* Price */}
              <FormControl isRequired isInvalid={!!errors.price}>
                <FormLabel>{t('unit_form.price')}</FormLabel>
                <Input
                  type="number"
                  {...register('price', { required: t('form.required') })}
                />
                <FormErrorMessage>{errors.price?.message}</FormErrorMessage>
              </FormControl>
            </Flex>

            {/* Late Payment Penalty Percent */}
            <FormControl isRequired isInvalid={!!errors.latePaymentPenaltyPercent}>
              <FormLabel>{t('unit_form.late_payment_penalty_percent')}</FormLabel>

              <Input type="number" {...register('latePaymentPenaltyPercent')} />

              <FormErrorMessage>
                {errors.latePaymentPenaltyPercent?.message}
              </FormErrorMessage>
            </FormControl>

            {/* Comments */}
            <FormControl>
              <FormLabel>{t('unit_form.comments')}</FormLabel>

              <Textarea maxLength={100} {...register('comments')} />

              <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
            </FormControl>

            {/* Active Status */}
            <FormControl>
              <Checkbox {...register('isActive')} defaultChecked={data?.isActive ?? true}>
                {t('unit_form.active')}
              </Checkbox>
            </FormControl>

            {/* Create more option */}
            {!data?._id && (
              <FormControl>
                <FormLabel>{t('form.additional_options')}</FormLabel>
                <Checkbox
                  isChecked={addMore}
                  onChange={(e) => setAddMore(e.target.checked)}
                >
                  {t('form.create_more')}
                </Checkbox>
              </FormControl>
            )}
          </Stack>
        </form>
      }
      footer={
        <HStack>
          <Button
            disabled={loading}
            type="submit"
            variant="brand"
            onClick={handleSubmit(submitTrigger)}
          >
            {data?._id ? t('form.update') : t('form.save')}
          </Button>

          <Button
            disabled={loading}
            variant="outline"
            onClick={() => handleOnClose(undefined)}
          >
            {t('form.cancel')}
          </Button>
        </HStack>
      }
    />
  );
};

export default observer(UnitForm);
