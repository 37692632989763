import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { Controller, useForm } from 'react-hook-form';
import {
  Stack,
  FormControl,
  FormLabel,
  Input,
  Select,
  CheckboxGroup,
  Checkbox,
  Button,
  HStack,
  Text,
  FormErrorMessage,
  Flex,
  SimpleGrid,
} from '@chakra-ui/react';

import Popup from '@/components/Popup';
import AppStore from '@/stores/app';
import useRequestState from '@/hooks/useRequestState';
import { createUser, updateUser } from '@/api/admin';
import { DEFAULT_FORM_DATA } from './config';
import { ADMIN_ROLE } from '@/constants/app';
import { isAdmin } from '@/utils/admin';

type Props = {
  data: Admin | null | undefined;
  setSelected: (data: Admin | null | undefined) => void;
  onSubmit: (response: Admin) => void;
};

const UserForm = ({ data, setSelected, onSubmit }: Props) => {
  const { t } = useTranslation();
  const { properties } = AppStore;

  const [addMore, setAddMore] = useState(false);

  const {
    register,
    watch,
    setValue,
    control,
    getValues,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<UserFormData | Admin>({
    defaultValues: DEFAULT_FORM_DATA,
    values: data ?? DEFAULT_FORM_DATA,
  });

  const propertyList = Object.values(properties);
  const role = watch('role');

  const handleOnClose = (data?: null | undefined) => {
    reset();
    setAddMore(data === null);
    setSelected(data);
  };

  const handleSuccess = (data: Admin) => {
    onSubmit(data);
    handleOnClose(addMore ? null : undefined);
  };

  const { loading, trigger: submitTrigger } = useRequestState<Admin>(
    () => {
      const body = getValues();
      const method = data ? updateUser : createUser;

      return method({ ...body, organization: undefined });
    },
    [],
    { condition: false, onSuccess: handleSuccess },
  );

  useEffect(() => {
    if (isAdmin(role)) {
      setValue('properties', []);
    }
  }, [role, setValue]);

  return (
    <Popup
      size="lg"
      isOpen={data !== undefined}
      onClose={() => handleOnClose(undefined)}
      title={data?._id ? t('user_form.edit_user') : t('user_form.create_user')}
      content={
        <form>
          <Stack spacing={4}>
            <Flex
              direction={{ base: 'column', md: 'row' }}
              gap={{
                base: 2,
                md: 5,
              }}
            >
              {/* Name */}
              <FormControl isRequired isInvalid={!!errors.name}>
                <FormLabel>{t('user_form.name')}</FormLabel>

                <Input {...register('name', { required: t('form.required') })} />

                <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
              </FormControl>

              {/* Last Name */}
              <FormControl isRequired isInvalid={!!errors.lastName}>
                <FormLabel>{t('user_form.last_name')}</FormLabel>

                <Input {...register('lastName', { required: t('form.required') })} />

                <FormErrorMessage>{errors.lastName?.message}</FormErrorMessage>
              </FormControl>
            </Flex>

            <Flex
              direction={{ base: 'column', md: 'row' }}
              gap={{
                base: 2,
                md: 5,
              }}
            >
              {/* Email */}
              <FormControl isRequired isInvalid={!!errors.email}>
                <FormLabel>{t('user_form.email')}</FormLabel>

                <Input
                  type="email"
                  {...register('email', { required: t('form.required') })}
                />

                <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
              </FormControl>

              {/* Role */}
              <FormControl isRequired isInvalid={!!errors.role}>
                <FormLabel>
                  {t('user_form.role', { required: t('form.required') })}
                </FormLabel>

                <Select
                  isRequired
                  isInvalid={!!errors.role}
                  placeholder={t('user_form.select_role')}
                  {...register('role', { required: t('form.required') })}
                >
                  <option value="admin">Admin</option>
                  <option value="manager">Manager</option>
                </Select>

                <FormErrorMessage>{errors.role?.message}</FormErrorMessage>
              </FormControl>
            </Flex>

            {/* Properties */}
            {role === ADMIN_ROLE.MANAGER && (
              <>
                <FormControl isRequired isInvalid={!!errors.properties}>
                  <FormLabel>{t('user_form.properties')}</FormLabel>

                  <FormErrorMessage>{errors.properties?.message}</FormErrorMessage>
                </FormControl>

                <Controller
                  name="properties"
                  rules={{ required: t('form.required') }}
                  control={control}
                  render={({ field }) => {
                    const { value, onChange } = field;

                    return (
                      <CheckboxGroup value={value} onChange={onChange}>
                        <SimpleGrid columns={2} spacing={2}>
                          {propertyList.map((property) => (
                            <Checkbox key={property._id} value={property._id}>
                              {property.name}
                            </Checkbox>
                          ))}
                        </SimpleGrid>
                      </CheckboxGroup>
                    );
                  }}
                />

                {!propertyList.length && (
                  <Text color="gray.500">{t('user_form.no_properties')}</Text>
                )}
              </>
            )}

            {/* Cretea more option */}
            {!data?._id && (
              <FormControl>
                <FormLabel>{t('form.additional_options')}</FormLabel>
                <Checkbox
                  isChecked={addMore}
                  onChange={(e) => setAddMore(e.target.checked)}
                >
                  {t('form.create_more')}
                </Checkbox>
              </FormControl>
            )}
          </Stack>
        </form>
      }
      footer={
        <HStack>
          <Button
            disabled={loading}
            type="submit"
            variant="brand"
            onClick={handleSubmit(submitTrigger)}
          >
            {data?._id ? t('form.update') : t('form.save')}
          </Button>

          <Button
            disabled={loading}
            variant="outline"
            onClick={() => handleOnClose(undefined)}
          >
            {t('form.cancel')}
          </Button>
        </HStack>
      }
    />
  );
};

export default observer(UserForm);
