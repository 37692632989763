import moment from 'moment';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import 'moment/locale/pl';
import 'moment/locale/uk';
import 'moment/locale/ru';

import { DEFAULT_LANGUAGE, SUPPORTED_LANGUAGES } from '@/constants/app';

import en from '@/locales/en.json';
import pl from '@/locales/pl.json';
import uk from '@/locales/uk.json';
import ru from '@/locales/ru.json';

const resources = {
  en: { translation: en },
  pl: { translation: pl },
  uk: { translation: uk },
  ru: { translation: ru },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    supportedLngs: Object.values(SUPPORTED_LANGUAGES),
    fallbackLng: DEFAULT_LANGUAGE,
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: [
        'querystring',
        'cookie',
        'localStorage',
        'navigator',
        'htmlTag',
        'path',
        'subdomain',
      ],
      caches: ['cookie'],
    },
    react: {
      useSuspense: false,
    },
  });

i18n.on('languageChanged', (lng) => {
  moment.locale(lng);
});

export default i18n;
