import { Box, Flex, Text, useColorModeValue } from '@chakra-ui/react';

import HeaderLinks from './HeaderLinks';

type Props = {
  title: string;
};

export default function AdminNavbar({ title }: Props) {
  const navbarBackdrop = 'blur(20px)';
  const navbarBg = useColorModeValue('rgba(244, 247, 254, 0.2)', 'rgba(11,20,55,0.5)');

  return (
    <Flex
      position="fixed"
      zIndex={2}
      boxShadow="none"
      bg={navbarBg}
      borderColor="transparent"
      filter="none"
      backdropFilter={navbarBackdrop}
      backgroundPosition="center"
      backgroundSize="cover"
      borderRadius={0}
      align="center"
      justify="center"
      px="20px"
      py="20px"
      top="0px"
      left={{ base: '0', xl: '300px' }}
      w={{
        base: '100vw',
        xl: 'calc(100vw - 300px)',
      }}
    >
      <Flex w="100%">
        <Box display={{ base: 'none', md: 'flex' }}>
          <Text
            fontWeight="bold"
            fontSize="30px"
            lineHeight={{
              md: '60px',
            }}
          >
            {title}
          </Text>
        </Box>

        <Box ms="auto" w={{ sm: '100%', md: 'unset' }}>
          <HeaderLinks title={title} />
        </Box>
      </Flex>
    </Flex>
  );
}
