import axios from 'axios';

import { getItem } from '@/utils/storage';
import { API_URL, SELECTED_PROPERTY_HEADER } from '@/constants/app';
import { logout } from './app';
import logger from './logger';
import i18n from './i18n';

export const request = async <T>({
  url,
  method = 'GET',
  body,
  headers = {},
  query = {},
  param = '',
  config,
}: ReqParams): Promise<CustomResponse<T>> => {
  try {
    const { skipStringify = false } = config || {};
    let _body = body ?? (undefined as any);
    const _query = new URLSearchParams(filterQuery(query));
    const _headers: Record<string, string> = {
      'Content-Type': 'application/json',
      'Accept-Language': i18n.language,
      ...headers,
    };

    const token = getItem('TOKEN');
    const property = getItem('SELECTED_PROPERTY');

    if (property) {
      _headers[SELECTED_PROPERTY_HEADER] = property;
    }

    if (token) {
      _headers.authorization = token;
    }

    if (!skipStringify) {
      _body = JSON.stringify(body);
    }

    const response = await axios(`${API_URL}${url}/${param}?${_query}`, {
      method,
      data: _body,
      headers: _headers,
      withCredentials: false,
    });

    logger('API', url, response.data);

    return response.data;
  } catch (error: any) {
    if (error.response) {
      const { data } = error.response;

      if (error.status === 401) {
        logout();
      }

      if (data) {
        return { success: false, error: data.error };
      }
    }

    logger('API', url, error);

    return { success: false, error };
  }
};

const filterQuery = (data: Record<any, any>) =>
  Object.fromEntries(Object.entries(data).filter(([_, value]) => value !== undefined));

type ReqParams = {
  url: ApiURLs;
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
  body?: Record<string, any>;
  headers?: Record<string, any>;
  param?: string;
  query?: Record<string, any>;
  config?: { skipStringify: boolean };
};
