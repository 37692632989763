import { COUNTRIES } from '@/constants/app';

export const DEFAULT_FORM_DATA: OrganizationFormData = {
  isActive: true,
  name: '',
  phone: '',
  email: '',

  country: COUNTRIES.PL,
  city: '',
  address: '',
};
