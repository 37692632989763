import 'react-calendar/dist/Calendar.css';
import '@/assets/css/MiniCalendar.css';

import { createRef, useEffect } from 'react';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import { Box, Text, Icon, PositionProps, Card } from '@chakra-ui/react';
import { Calendar } from 'react-calendar';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onChange: (value: CalendarValue) => void;
  value: CalendarValue;
  selectRange?: boolean;
  maxDate?: Date;
  minDate?: Date;
  position?: PositionProps['position'];
  fullSize?: boolean;
} & {
  [key: string]: any;
};

const MiniCalendar = ({
  isOpen,
  onClose,
  onChange,
  value,
  selectRange = false,
  maxDate,
  minDate,
  position = 'absolute',
  fullSize = false,
  ...rest
}: Props) => {
  const ref = createRef<HTMLDivElement>();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose, ref]);

  if (!isOpen) return null;

  return (
    <Box position={position} zIndex={100} ref={ref} {...rest}>
      <Card
        alignItems="center"
        flexDirection="column"
        w="100%"
        maxW={fullSize ? '100%' : 'max-content'}
        p="20px 15px"
        h={fullSize ? '100%' : 'max-content'}
        justifyContent="center"
        onClick={(e) => e.stopPropagation()}
      >
        <Calendar
          onChange={(data) => onChange(data as CalendarValue)}
          value={value}
          selectRange={selectRange}
          view="month"
          tileContent={<Text color="brand.500" />}
          prevLabel={<Icon as={MdChevronLeft} w="24px" h="24px" mt="4px" />}
          nextLabel={<Icon as={MdChevronRight} w="24px" h="24px" mt="4px" />}
          maxDate={maxDate}
          minDate={minDate}
        />
      </Card>
    </Box>
  );
};

export default MiniCalendar;
