import { Box, Flex, Stack, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';

import AuthStore from '@/stores/auth';
import { HSeparator } from '@/components/Separator';
import Brand from './Brand';
import Links from './Links';
import LINKS, { LINKS_ADMIN, LINKS_SUPER_ADMIN } from '../Links';
import PropertySelector from './PropertySelector';
import { isAdmin, isSuperAdmin } from '@/utils/admin';

type Props = {
  closeBtn?: React.ReactNode;
};

function SidebarContent({ closeBtn }: Props) {
  const { profile } = AuthStore;
  const { t } = useTranslation();

  return (
    <Flex direction="column" position="relative" pb="20px" flex={1} minH="100%">
      <Brand />

      {closeBtn}

      <Stack direction="column" flex={1}>
        <HSeparator />

        <PropertySelector />

        <Flex align="center">
          <HSeparator />

          <Text mx={3} color="gray.400">
            {t('sidebar.management')}
          </Text>

          <HSeparator />
        </Flex>

        <Box ps="20px">
          <Links links={LINKS} />
        </Box>

        {isAdmin(profile?.role) && (
          <>
            <Flex align="center" mt="auto">
              <HSeparator />

              <Text mx={3} color="gray.400">
                {t('sidebar.administration')}
              </Text>

              <HSeparator />
            </Flex>

            <Box ps="20px" justifySelf="flex-end">
              <Links links={LINKS_ADMIN} />
            </Box>
          </>
        )}

        {isSuperAdmin(profile?.role) && (
          <>
            <Flex align="center" mt="auto">
              <HSeparator />

              <Text mx={3} color="gray.400" whiteSpace="nowrap">
                {t('sidebar.super_administration')}
              </Text>

              <HSeparator />
            </Flex>

            <Box ps="20px" justifySelf="flex-end">
              <Links links={LINKS_SUPER_ADMIN} />
            </Box>
          </>
        )}
      </Stack>
    </Flex>
  );
}

export default observer(SidebarContent);
