import {
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Stack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

const PropertyContact = () => {
  const { t } = useTranslation();

  const {
    formState: { errors },
    register,
    watch,
  } = useFormContext<OrganizationFormData>();

  const isActive = watch('isActive');

  return (
    <Stack spacing={4}>
      <Flex gap={{ base: 4, md: 2 }} direction="column">
        {/* Active Status */}
        <FormControl>
          <Checkbox {...register('isActive')} defaultChecked={isActive ?? true}>
            {t('property_form.active')}
          </Checkbox>
        </FormControl>

        {/* Name */}
        <FormControl isRequired isInvalid={!!errors.name}>
          <FormLabel>{t('property_form.name')}</FormLabel>

          <Input {...register('name', { required: t('form.required') })} />

          <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
        </FormControl>

        <Flex
          gap={{
            base: 4,
            md: 2,
          }}
          direction={{
            base: 'column',
            md: 'row',
          }}
        >
          <FormControl isInvalid={!!errors.phone} isRequired>
            <FormLabel>{t('property_form.phone')}</FormLabel>

            <Input
              placeholder={'48000111333'}
              {...register('phone', { required: t('form.required') })}
            />

            <FormErrorMessage>{errors.phone?.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.email} isRequired>
            <FormLabel>{t('property_form.email')}</FormLabel>

            <Input {...register('email', { required: t('form.required') })} />

            <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
          </FormControl>
        </Flex>
      </Flex>
    </Stack>
  );
};

export default PropertyContact;
