import { request } from '@/utils/request';

export const getOrganizations = async (params: any) =>
  request<PaginateResult<Organization>>({
    url: '/organization',
  });

export const createOrganization = async (body: Partial<Organization>) =>
  request<'ok'>({
    url: '/organization',
    method: 'POST',
    body,
  });

export const updateOrganization = async (body: Partial<Organization>) =>
  request<'ok'>({
    url: '/organization',
    method: 'PUT',
    param: body._id,
    body,
  });
