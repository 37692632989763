import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  Stack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import { DEFAULT_LANGUAGE, SUPPORTED_LANGUAGES } from '@/constants/app';

type Props = {
  data?: Client | null;
};

const ClientDataForm = ({ data }: Props) => {
  const { t } = useTranslation();

  const {
    formState: { errors },
    register,
  } = useFormContext<ClientFormData>();

  return (
    <Stack spacing={4}>
      <Flex gap={{ base: 4, md: 2 }} direction={{ base: 'column', md: 'row' }}>
        <FormControl isRequired isInvalid={!!errors.name}>
          <FormLabel>{t('clients_form.name')}</FormLabel>

          <Input {...register('name', { required: t('form.required') })} />

          <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isRequired isInvalid={!!errors.lastName}>
          <FormLabel>{t('clients_form.last_name')}</FormLabel>

          <Input {...register('lastName', { required: t('form.required') })} />

          <FormErrorMessage>{errors.lastName?.message}</FormErrorMessage>
        </FormControl>
      </Flex>

      <Flex gap={{ base: 4, md: 2 }} direction={{ base: 'column', md: 'row' }}>
        <FormControl isRequired isInvalid={!!errors.email}>
          <FormLabel>{t('clients_form.email')}</FormLabel>

          <Input
            type="email"
            {...register('email', {
              required: t('form.required'),
              pattern: {
                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                message: t('form.invalid_email'),
              },
            })}
          />

          <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isRequired isInvalid={!!errors.phone}>
          <FormLabel>{t('clients_form.phone')}</FormLabel>

          <Input
            type="tel"
            placeholder={'48000111333'}
            maxLength={15}
            {...register('phone', {
              required: t('form.required'),
              pattern: {
                value: /^[0-9]{10,15}$/,
                message: t('form.invalid_phone'),
              },
            })}
          />

          <FormErrorMessage>{errors.phone?.message}</FormErrorMessage>
        </FormControl>
      </Flex>

      <Flex gap={{ base: 4, md: 2 }} direction={{ base: 'column', md: 'row' }}>
        <FormControl isRequired isInvalid={!!errors.dob}>
          <FormLabel>{t('clients_form.dob')}</FormLabel>

          <Input
            type="date"
            placeholder={t('clients_form.date_format')}
            {...register('dob', { required: t('form.required') })}
          />

          <FormErrorMessage>{errors.dob?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isRequired isInvalid={!!errors.lastName}>
          <FormLabel>{t('clients_form.language')}</FormLabel>

          <Select
            disabled={!!data}
            defaultValue={DEFAULT_LANGUAGE}
            {...register('language', { required: t('form.required') })}
          >
            {Object.values(SUPPORTED_LANGUAGES).map((lang) => (
              <option key={lang} value={lang}>
                {t(`languages.${lang}`)}
              </option>
            ))}
          </Select>

          <FormErrorMessage>{errors.language?.message}</FormErrorMessage>
        </FormControl>
      </Flex>
    </Stack>
  );
};

export default ClientDataForm;
