import { extendTheme, HTMLChakraProps, ThemingProps } from '@chakra-ui/react';

import { buttonStyles } from './components/button';
import { badgeStyles } from './components/badge';
import { inputStyles } from './components/input';
import { progressStyles } from './components/progress';
import { sliderStyles } from './components/slider';
import { textareaStyles } from './components/textarea';
import { switchStyles } from './components/switch';
import { linkStyles } from './components/link';
import { radioStyles } from './components/radio';
import { cardStyles } from './components/card';
import { breakpoints } from './foundations/breakpoints';
import { globalStyles } from './styles';
import { iconButtonStyles } from './components/iconButton';
import { menuStyles } from './components/menu';

export default extendTheme(
  { breakpoints },
  globalStyles,
  cardStyles,
  badgeStyles,
  buttonStyles,
  linkStyles,
  progressStyles,
  sliderStyles,
  inputStyles,
  textareaStyles,
  switchStyles,
  radioStyles,
  iconButtonStyles,
  menuStyles,
);

export interface CustomCardProps extends HTMLChakraProps<'div'>, ThemingProps {}
