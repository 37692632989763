export const menuStyles = {
  components: {
    Menu: {
      baseStyle: {
        boxShadow: 'md',
      },
    },
    MenuList: {
      baseStyle: {
        boxShadow: 'md',
      },
    },
  },
};
