import { NavLink, useLocation } from 'react-router-dom';
import { Box, Flex, HStack, Text, useColorModeValue } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import LayoutStore from '@/stores/layout';

type Props = {
  links: RoutesType[];
};

export function SidebarLinks(props: Props) {
  const { links } = props;

  const { t } = useTranslation();
  const location = useLocation();
  const activeColor = useColorModeValue('brand.500', 'white');
  const textColor = useColorModeValue('secondaryGray.500', 'secondaryGray.500');
  const brandColor = useColorModeValue('brand.500', 'brand.400');

  const { setMenuOpen } = LayoutStore;

  const activeRoute = (routeName: string) => {
    return location.pathname.includes(routeName);
  };

  const handleLinkClick = () => {
    setTimeout(() => {
      setMenuOpen(false);
    }, 500);
  };

  const createLinks = (routes: RoutesType[]) => {
    return routes.map((route: RoutesType, index: number) => {
      const isActive = activeRoute(route.path.toLowerCase());

      return (
        <NavLink key={index} to={route.path} onClick={handleLinkClick}>
          <HStack py="3px" h="40px">
            <Flex w="100%" alignItems="center" justifyContent="center">
              <Box color={isActive ? activeColor : textColor} me="18px">
                {route.icon}
              </Box>

              <Text
                me="auto"
                mt="-1"
                color={isActive ? activeColor : textColor}
                fontWeight="bold"
              >
                {t(route.name)}
              </Text>
            </Flex>

            <Box w="4px" bg={isActive ? brandColor : 'transparent'} borderRadius="5px" />
          </HStack>
        </NavLink>
      );
    });
  };

  return <>{createLinks(links)}</>;
}

export default SidebarLinks;
