export const ENV = process.env.REACT_APP_NODE_ENV || 'development';
export const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3000';

export const GOOGLE_CLIENT_ID =
  '647434470569-e802rqp0779bjtq14podicc6bq6o959l.apps.googleusercontent.com';

export const GET_HELP_EMAIL = 'admin@leezly.com';
export const DOCUMENTATION_URL =
  'https://leezly.notion.site/Leezly-Property-Management-System-f8fac058d46e4320bbb1986516672f9f';

export const OTP_LENGTH = 4;
export const PAGINATION_DEFAULT_LIMIT = 10;

export const MIN_PAYMENT_DAY = 1;
export const MAX_PAYMENT_DAY = 28;

export const SELECTED_PROPERTY_HEADER = 'x-selected-property';

export enum CURRENCY {
  USD = 'USD',
  EUR = 'EUR',
  PLN = 'PLN',
}

export enum COUNTRIES {
  PL = 'PL',
}

export const SUPPORTED_LANGUAGES = {
  EN: 'en',
  RU: 'ru',
  UK: 'uk',
  PL: 'pl',
} as const;

export const DEFAULT_LANGUAGE = SUPPORTED_LANGUAGES.EN;

export enum CONTRACT_STATUS {
  DRAFT = 'DRAFT',
  PENDING_SIGNATURE = 'PENDING_SIGNATURE',
  ACTIVE = 'ACTIVE',
  CLOSED = 'CLOSED',
}

export enum INVOICE_STATUS {
  DRAFT = 'DRAFT',
  PENDING = 'PENDING',
  PAID = 'PAID',
  OVERDUE = 'OVERDUE',
  REFUNDED = 'REFUNDED',
  CANCELLED = 'CANCELLED',
}

export enum INVOICE_TYPE {
  REGULAR = 'REGULAR',
  CUSTOM = 'CUSTOM',
}

export enum INVOICE_ITEM_TYPE {
  DEPOSIT = 'DEPOSIT',
  REGULAR = 'REGULAR',
  OVERDUE_PENALTY = 'OVERDUE_PENALTY',
  CUSTOM = 'CUSTOM',
}

export const UPAID_INVOICE_STATUSES: InvoiceStatus[] = [
  INVOICE_STATUS.PENDING,
  INVOICE_STATUS.OVERDUE,
];

export enum DOCUMENT_TYPE {
  PASSPORT = 'PASSPORT',
  DRIVING_LICENSE = 'DRIVING_LICENSE',
  ID_CARD = 'ID_CARD',
}

export enum PAYMENT_SHIFT_DAY {
  MIN = -15,
  MAX = 15,
  DEFAULT = 0,
}

export const INVOICE_EXPIRATION_DAYS = 7;

export enum COMPLAIN_STATUS {
  PENDING = 'PENDING',
  RESOLVED = 'RESOLVED',
  REJECTED = 'REJECTED',
}

export enum DATE_FILTERS {
  THIS_MONTH = 'THIS_MONTH',
  LAST_MONTH = 'LAST_MONTH',
  THIS_YEAR = 'THIS_YEAR',
  LAST_YEAR = 'LAST_YEAR',
}

export enum TASK_STATUS {
  TODO = 'TODO',
  IN_PROGRESS = 'IN_PROGRESS',
  DONE = 'DONE',
}

export enum TASK_TYPE {
  TASK = 'TASK',
  BUG = 'BUG',
}

export enum ADMIN_ROLE {
  ADMIN = 'ADMIN',
  MANAGER = 'MANAGER',
  SUPER_ADMIN = 'SUPER_ADMIN',
}
