import { useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Badge, Flex, HStack, IconButton, Text } from '@chakra-ui/react';
import { IoAdd, IoGridOutline, IoReload } from 'react-icons/io5';
import { FaPencilAlt, FaTrash } from 'react-icons/fa';

import DataTable from '@/components/Table';
import { deleteUnit, getUnits } from '@/api/unit';
import Pagination from '@/components/Pagination';
import usePageInfo from '@/hooks/usePageInfo';
import usePagination from '@/hooks/usePagination';
import useRequestState from '@/hooks/useRequestState';
import ActionButton from '@/components/ActionButton';
import AppStore from '@/stores/app';
import UnitForm from './components/form';
import ConfirmDialog from '@/components/Dialogs/ConfirmDialog';
import { formatDate } from '@/utils/date';
import ActionBar from '@/components/ActionBar';

const UnitsPage = () => {
  usePageInfo({ title: 'pages.units' });

  const { t } = useTranslation();
  const { selectedProperty } = AppStore;

  const [selectedUnit, setSelectedUnit] = useState<Unit | null | undefined>(undefined);
  const [toDelete, setTodelete] = useState<Unit | null>(null);

  const {
    page,
    limit,
    sortBy,
    order,
    search,
    setPage,
    toggleOrder,
    setLimit,
    setSearch,
  } = usePagination<Unit>('name');

  const handleDialogToggle = useCallback((data?: Unit | null) => {
    setTodelete(data ?? null);
  }, []);

  const { data, trigger: dataTrigger } = useRequestState<PaginateResult<Unit>>(
    () => getUnits({ page, limit, sortBy, order, search }),
    [page, limit, sortBy, order, selectedProperty, search],
  );

  const onDeleteSuccess = useCallback(() => {
    dataTrigger();
    handleDialogToggle(null);
  }, [dataTrigger, handleDialogToggle]);

  const { trigger: deleteTrigger } = useRequestState<'ok'>(
    () => deleteUnit(toDelete?._id!),
    [toDelete],
    { condition: false, onSuccess: onDeleteSuccess },
  );

  return (
    <>
      <Flex direction="column" h="100%">
        <ActionBar
          setSearch={setSearch}
          stats={
            <ActionButton
              mini
              hoverable={false}
              name={t('units.total')}
              value={`${data?.totalDocs ?? '-'}`}
              icon={IoGridOutline}
            />
          }
          actions={
            <>
              <ActionButton mini icon={IoAdd} onClick={() => setSelectedUnit(null)} />

              <ActionButton mini icon={IoReload} onClick={dataTrigger} />
            </>
          }
        />

        <DataTable<Unit>
          mt="20px"
          sortBy={sortBy}
          order={order}
          onSort={toggleOrder}
          data={data?.docs || []}
          columns={[
            {
              id: 'name',
              header: t('units.name'),
              accessor: 'name',
              isSortable: true,
            },
            {
              id: 'isActive',
              header: t('units.status'),
              accessor: 'isActive',
              isSortable: true,
              cell: (unit: Unit) => (
                <Badge colorScheme={unit.isActive ? 'green' : 'red'}>
                  {unit.isActive ? 'Active' : 'Inactive'}
                </Badge>
              ),
            },
            {
              id: 'deposit',
              header: t('units.deposit'),
              accessor: 'deposit',
              isSortable: true,
            },
            {
              id: 'price',
              header: t('units.price'),
              accessor: 'price',
              isSortable: true,
            },
            {
              id: 'comments',
              header: t('units.comments'),
              accessor: 'comments',
            },
            {
              id: 'createdAt',
              header: t('units.created_at'),
              accessor: 'createdAt',
              isSortable: true,
              cell: (unit: Unit) => <Text>{formatDate(unit.createdAt)}</Text>,
            },
            {
              id: 'actions',
              header: t('units.actions'),
              accessor: null,
              center: true,
              cell: (data: Unit) => (
                <HStack justify="center">
                  <IconButton
                    aria-label="Update"
                    size="sm"
                    icon={<FaPencilAlt />}
                    onClick={() => setSelectedUnit(data)}
                  />

                  <IconButton
                    aria-label="Delete"
                    size="sm"
                    icon={<FaTrash />}
                    onClick={() => handleDialogToggle(data)}
                  />
                </HStack>
              ),
            },
          ]}
          pagination={
            <Pagination
              {...data}
              onPageSizeChange={setLimit}
              onPageChange={setPage}
              limit={limit}
            />
          }
        />
      </Flex>

      <UnitForm
        data={selectedUnit}
        setSelected={setSelectedUnit}
        onSubmit={dataTrigger}
      />

      <ConfirmDialog
        isOpen={!!toDelete}
        title={t('units.delete_confirmation_title')}
        body={t('units.delete_confirmation_body', {
          name: `${toDelete?.name}`,
        })}
        onConfirm={deleteTrigger}
        onCancel={() => handleDialogToggle()}
      />
    </>
  );
};

export default observer(UnitsPage);
