import { request } from '@/utils/request';

export const searchClients = async (query: PaginationRequestType) =>
  request<PaginateResult<Client>>({
    url: '/clients/search',
    query,
  });

export const getPropertyClient = async (query: PaginationRequestType) =>
  request<PaginateResult<Client>>({
    url: '/clients',
    query,
  });

export const createClient = async (body: ClientFormData) =>
  request<Client>({
    url: '/client',
    method: 'POST',
    body,
  });

export const updateClient = async (id: Client['_id'], body: ClientFormData) =>
  request<Client>({
    url: '/client',
    method: 'PUT',
    param: id,
    body,
  });

export const deleteClient = async (id: string) =>
  request<'ok'>({
    url: '/client',
    method: 'DELETE',
    param: id,
  });
