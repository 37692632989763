import { COUNTRIES } from '@/constants/app';
import {
  Stack,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const PropertyAddress = () => {
  const { t } = useTranslation();

  const {
    formState: { errors },
    register,
  } = useFormContext<PropertyFormData>();

  return (
    <Stack spacing={4}>
      <Flex gap={{ base: 4, md: 2 }} direction="column">
        <Flex
          gap={{
            base: 4,
            md: 2,
          }}
          direction={{
            base: 'column',
            md: 'row',
          }}
        >
          <FormControl isRequired isInvalid={!!errors.address?.country}>
            <FormLabel>{t('property_form.country')}</FormLabel>

            <Select
              placeholder={t('property_form.select_country')}
              {...register('address.country', { required: t('form.required') })}
            >
              {Object.values(COUNTRIES).map((coutnry) => (
                <option key={coutnry} value={coutnry}>
                  {t(`countries.${coutnry}`)}
                </option>
              ))}
            </Select>

            <FormErrorMessage>{errors.address?.country?.message}</FormErrorMessage>
          </FormControl>

          <FormControl isRequired isInvalid={!!errors.address?.city}>
            <FormLabel>{t('property_form.city')}</FormLabel>

            <Input {...register('address.city', { required: t('form.required') })} />

            <FormErrorMessage>{errors.address?.city?.message}</FormErrorMessage>
          </FormControl>
        </Flex>

        <Flex
          gap={{
            base: 4,
            md: 2,
          }}
          direction={{
            base: 'column',
            md: 'row',
          }}
        >
          <FormControl isRequired isInvalid={!!errors.address?.state}>
            <FormLabel>{t('property_form.state')}</FormLabel>

            <Input {...register('address.state', { required: t('form.required') })} />

            <FormErrorMessage>{errors.address?.state?.message}</FormErrorMessage>
          </FormControl>

          <FormControl isRequired isInvalid={!!errors.address?.zip}>
            <FormLabel>{t('property_form.zip')}</FormLabel>

            <Input {...register('address.zip', { required: t('form.required') })} />

            <FormErrorMessage>{errors.address?.zip?.message}</FormErrorMessage>
          </FormControl>
        </Flex>

        {/* Address Fields */}
        <FormControl isRequired isInvalid={!!errors.address?.street}>
          <FormLabel>{t('property_form.street')}</FormLabel>

          <Input {...register('address.street', { required: t('form.required') })} />

          <FormErrorMessage>{errors.address?.street?.message}</FormErrorMessage>
        </FormControl>

        <Flex
          gap={{
            base: 4,
            md: 2,
          }}
          direction={{
            base: 'column',
            md: 'row',
          }}
        >
          <FormControl isInvalid={!!errors.address?.building} isRequired>
            <FormLabel>{t('property_form.building')}</FormLabel>

            <Input {...register('address.building')} />

            <FormErrorMessage>{errors.address?.building?.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.address?.unit}>
            <FormLabel>{t('property_form.unit')}</FormLabel>

            <Input {...register('address.unit')} />

            <FormErrorMessage>{errors.address?.unit?.message}</FormErrorMessage>
          </FormControl>
        </Flex>
      </Flex>
    </Stack>
  );
};

export default PropertyAddress;
