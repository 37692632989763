import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToast } from '@chakra-ui/react';

type RequestState<T, D = unknown> = {
  loading: boolean;
  error?: string;
  data?: T;
  setData: (data: T) => void;
  trigger: (data?: D) => void;
};

const useRequestState = <T, D = unknown>(
  promise: (data?: D) => Promise<CustomResponse<T>>,
  deps: any[] = [],
  config: {
    withNotify?: boolean;
    condition?: boolean;
    onSuccess?: (data: T) => void;
    onFinally?: () => void;
  } = {
    withNotify: true,
    condition: true,
  },
): RequestState<T, D> => {
  const { withNotify = true, condition = true, onFinally, onSuccess } = config;
  const toast = useToast();
  const { t } = useTranslation();
  const [data, setData] = useState<T>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const trigger = useCallback(
    (data?: D) => {
      setError('');
      setLoading(true);

      promise(data)
        .then((result: CustomResponse<T>) => {
          if (result.success) {
            setData(result.data);

            if (onSuccess) {
              onSuccess(result.data as T);
            }
          } else {
            setError(result.error ?? t('server.error'));
          }
        })
        .catch((err) => {
          setError(err.message);
        })
        .finally(() => {
          setLoading(false);

          if (onFinally) {
            onFinally();
          }
        });
    },
    // eslint-disable-next-line
    [t, onFinally, onSuccess, ...deps],
  );

  useEffect(() => {
    if (condition) {
      trigger();
    }
    // eslint-disable-next-line
  }, [...deps, condition]);

  useEffect(() => {
    if (!!error && withNotify) {
      toast({
        description: error,
        status: 'error',
        duration: 4000,
        isClosable: true,
        position: 'top-right',
      });
    }
  }, [error, toast, withNotify]);

  return {
    loading,
    error,
    data,
    setData,
    trigger,
  };
};

export default useRequestState;
