import { Avatar, Card, Flex, Text, Tooltip, useColorModeValue } from '@chakra-ui/react';
import { Draggable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

import { formatDate } from '@/utils/date';
import { cutToLength } from '@/utils/text';
import TaskType from './TaskType';

type Props = {
  user?: Admin;
  index: number;
  task: Task;
  onClick?: () => void;
};

const TaskItem = ({
  index,
  user,
  onClick,
  task: { _id, title, description, createdAt, type },
}: Props) => {
  const { t } = useTranslation();

  const taskBgHover = useColorModeValue('gray.50', 'secondaryGray.700');

  const placeholder = t('tasks.not_assigned');
  const userName = user ? `${user?.name ?? ''} ${user?.lastName ?? ''}` : placeholder;

  return (
    <Draggable draggableId={_id} index={index}>
      {(provided) => (
        <Card
          key={_id}
          cursor="grab"
          transition="background 0.3s"
          mb="20px"
          _hover={{
            bg: taskBgHover,
          }}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          onClick={onClick}
        >
          <Flex w="100%">
            <Text flex={1} fontWeight="bold" isTruncated>
              {title}
            </Text>
          </Flex>

          {description && (
            <Text color="gray.400" pr="30px" mt="10px">
              {cutToLength(description, 80)}
            </Text>
          )}

          <Flex direction="row" justify="space-between" align="center" mt="10px">
            <TaskType type={type} />

            <Text color="gray.500" fontSize="xs" ml="10px">
              {formatDate(createdAt)}
            </Text>

            <Tooltip label={userName}>
              <Avatar
                bg={!user ? 'gray.500' : undefined}
                ml="auto"
                size="xs"
                name={userName}
              />
            </Tooltip>
          </Flex>
        </Card>
      )}
    </Draggable>
  );
};

export default TaskItem;
