import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  Stack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import { DOCUMENT_TYPE } from '@/constants/app';

type Props = {
  loading: boolean;
};

const DocumentForm = ({ loading }: Props) => {
  const { t } = useTranslation();

  const {
    formState: { errors },
    register,
  } = useFormContext<ClientFormData>();

  return (
    <Stack spacing={4}>
      <Flex gap={{ base: 4, md: 2 }} direction={{ base: 'column', md: 'row' }}>
        <FormControl isRequired isInvalid={!!errors.name}>
          <FormLabel>{t('clients_form.document_type')}</FormLabel>

          <Select
            disabled={loading}
            defaultValue={DOCUMENT_TYPE.PASSPORT}
            {...register('document.type', { required: t('form.required') })}
          >
            {Object.values(DOCUMENT_TYPE).map((type) => (
              <option key={type} value={type}>
                {t(`clients.document.type.${type}`)}
              </option>
            ))}
          </Select>

          <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isRequired isInvalid={!!errors.document?.number}>
          <FormLabel>{t('clients.document.number')}</FormLabel>

          <Input
            disabled={loading}
            {...register('document.number', { required: t('form.required') })}
          />

          <FormErrorMessage>{errors.document?.number?.message}</FormErrorMessage>
        </FormControl>
      </Flex>

      <Flex gap={{ base: 4, md: 2 }} direction={{ base: 'column', md: 'row' }}>
        <FormControl isRequired isInvalid={!!errors.document?.issued}>
          <FormLabel>{t('clients.document.issue_date')}</FormLabel>

          <Input
            type="date"
            disabled={loading}
            placeholder={t('clients_form.date_format')}
            {...register('document.issued', { required: t('form.required') })}
          />

          <FormErrorMessage>{errors.document?.issued?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isRequired isInvalid={!!errors.document?.expires}>
          <FormLabel>{t('clients.document.expiry_date')}</FormLabel>

          <Input
            type="date"
            disabled={loading}
            placeholder={t('clients_form.date_format')}
            {...register('document.expires', { required: t('form.required') })}
          />

          <FormErrorMessage>{errors.document?.expires?.message}</FormErrorMessage>
        </FormControl>
      </Flex>
    </Stack>
  );
};

export default DocumentForm;
