import React, { useRef } from 'react';
import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
  Spinner,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

interface ConfirmDialogProps {
  isOpen: boolean;
  title: string;
  loading?: boolean;
  body: string;
  confirmButtonColorScheme?: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
  onConfirm: () => void;
  onCancel: () => void;
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  isOpen,
  title,
  body,
  loading,
  confirmButtonColorScheme = 'red',
  confirmButtonText,
  cancelButtonText,
  onCancel,
  onConfirm,
}) => {
  const { t } = useTranslation();
  const cancelRef = useRef<HTMLButtonElement>(null);

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onCancel}
      isCentered
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {title}
          </AlertDialogHeader>

          <AlertDialogBody>{body}</AlertDialogBody>

          <AlertDialogFooter gap={2}>
            <Button
              colorScheme={confirmButtonColorScheme}
              onClick={onConfirm}
              ml={3}
              disabled={loading}
            >
              {loading ? <Spinner size="sm" /> : confirmButtonText || t('form.confirm')}
            </Button>

            <Button ref={cancelRef} onClick={onCancel} disabled={loading}>
              {cancelButtonText || t('form.cancel')}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default ConfirmDialog;
