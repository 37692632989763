import { ENV } from '@/constants/app';

const logger = (key: LogKeyType, ...args: any[]) => {
  if (ENV === 'development') {
    const data = args.length === 0 ? ['✓'] : args;

    console.log(`[${key}]::`, ...data);
  }
};

export default logger;
