import { TFunction } from 'i18next';

export const mapContractStatusToColor = (status: ContractStatus) => {
  switch (status) {
    case 'DRAFT':
      return 'gray';
    case 'PENDING_SIGNATURE':
      return 'yellow';
    case 'ACTIVE':
      return 'green';
    case 'CLOSED':
      return 'red';
    default:
      return 'gray';
  }
};

export const getStatusAvailability = (
  status: ContractStatus,
  current: ContractStatus,
) => {
  if (current === status) return true;
  if (current === 'DRAFT' && status === 'PENDING_SIGNATURE') return true;
  if (current === 'ACTIVE' && status === 'CLOSED') return true;
  if (current === 'PENDING_SIGNATURE' && status === 'CLOSED') return true;

  return false;
};

export const getContractChartData = (
  statsData: ContractStats | undefined,
  t: TFunction,
) => {
  if (!statsData) return [];

  return Object.keys(statsData).map((key) => ({
    label: t(`contracts.statuses.${key}`),
    value: statsData[key as keyof ContractStats],
  }));
};

export const getContractLegendChart = (data: ContractStats | undefined, t: TFunction) => {
  if (!data) return [];

  const total = Object.values(data).reduce((acc, val) => acc + val, 0);

  return Object.keys(data).map((key: string) => ({
    label: t(`contracts.statuses.${key}`),
    value: data[key as keyof ContractStats],
    percent: ((data[key as keyof ContractStats] / total) * 100).toFixed(0),
  }));
};
