import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { HiCursorClick } from 'react-icons/hi';
import { Flex, Icon, Text } from '@chakra-ui/react';

import AppStore from '@/stores/app';
import AuthStore from '@/stores/auth';
import { ADMIN_ROLE } from '@/constants/app';
import { isAdmin } from '@/utils/admin';

const SelectProperty = () => {
  const { t } = useTranslation();
  const { properties } = AppStore;
  const { profile } = AuthStore;

  return (
    <Flex
      alignSelf="center"
      justify="center"
      minH={'100%'}
      pb="20px"
      direction="column"
      maxW="400px"
    >
      <Flex direction="row" justify="center" align="center" mb="30px" gap="10px">
        <Icon as={HiCursorClick} fontSize="2xl" />

        <Text fontSize="2xl">{t('general.property_not_selected_title')}</Text>
      </Flex>

      {profile?.role === ADMIN_ROLE.MANAGER && (
        <Text align="center" mb="20px">
          {t(
            properties.length
              ? 'general.property_not_selected_description'
              : 'general.property_no_length',
          )}
        </Text>
      )}

      {isAdmin(profile?.role) && (
        <Text align="center" mb="20px">
          {t(
            properties.length
              ? 'general.property_not_selected_description'
              : 'general.property_no_length_admin',
          )}
        </Text>
      )}
    </Flex>
  );
};

export default observer(SelectProperty);
