import { useCallback, useMemo } from 'react';
import { Outlet, useMatches } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Box, Flex, Spinner } from '@chakra-ui/react';

import { getProfile } from '@/api/admin';
import { getProperties } from '@/api/property';
import AuthStore from '@/stores/auth';
import LayoutStore from '@/stores/layout';
import AppStore from '@/stores/app';
import useRequestState from '@/hooks/useRequestState';
import Sidebar, { SidebarResponsive } from './components/Sidebar';
import AdminNavbar from './components/NavBar';
import SelectProperty from './components/SelectProperty';

const AdminLayout = () => {
  const { pageTitle } = LayoutStore;
  const { setProfile } = AuthStore;
  const { selectedProperty, setOrganization, setProperties, organization } = AppStore;

  const matches = useMatches();

  const propertySpecific = useMemo(
    () =>
      matches.some((data) => {
        const route = data as CustomRouteConfig;

        return !!route.handle?.propertySpecific;
      }),
    [matches],
  );

  const onProfileSuccess = useCallback(
    (profile: Profile) => {
      if (profile) {
        setProfile(profile);
        setOrganization(profile.organization);
      }
    },
    [setProfile, setOrganization],
  );

  const onPropertySuccess = useCallback(
    ({ docs: properties }: PaginateResult<Property>) => {
      setProperties(properties);
    },
    [setProperties],
  );

  const { loading: profileLoading } = useRequestState<Profile>(() => getProfile(), [], {
    onSuccess: onProfileSuccess,
  });

  const { loading: propertyLoading } = useRequestState<PaginateResult<Property>>(
    () => getProperties(),
    [organization?._id],
    { onSuccess: onPropertySuccess, condition: !!organization?._id },
  );

  if (profileLoading || propertyLoading) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100vh"
        width="100%"
      >
        <Spinner size="xl" />
      </Box>
    );
  }

  return (
    <>
      <Sidebar />

      <SidebarResponsive />

      <Flex
        overflow="auto"
        position="relative"
        direction="column"
        ml={{ base: 0, xl: '300px' }}
        px="20px"
        pt="100px"
        h="100vh"
        w={{ base: '100%', xl: 'calc( 100% - 300px )' }}
        maxWidth={{ base: '100%', xl: 'calc( 100vw - 300px )' }}
        justifyContent="stretch"
      >
        <Flex w="100%" flex={1} direction="column">
          {!selectedProperty && propertySpecific ? <SelectProperty /> : <Outlet />}
        </Flex>
      </Flex>

      <AdminNavbar title={pageTitle} />
    </>
  );
};

export default observer(AdminLayout);
